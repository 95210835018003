<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">{{ $t('decision.stat_condition') }}</span>
      </div>
      <div class="inp-list">
        <!-- <div class="item" v-if="!isOperator()">
          <span class="label">{{ $t('g.operator') }}</span>
          <el-select size="small" v-model="formData.valOperator" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.operators" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div> -->
        <div class="item" >
          <span class="label">{{ $t('g.operator') }}</span>
          <el-cascader size="small" :clearable="true" v-model="formData.valOperator" :props="formData.props" :options="cascade_options_direct" />
        </div>
        <div class="item">
          <span class="label">{{ $t('decision.type') }}</span>
          <el-select size="small" v-model="formData.valType" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.types" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t('decision.cycle') }}</span>
          <el-select size="small" v-model="formData.valCircle" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.circles" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t('decision.time') }}</span>
          <el-date-picker size="small" v-model="formData.valTime" :type="formData.valCircle == 1 ? 'year' : 'month'"
            :placeholder="$t('g.choose')">
          </el-date-picker>
        </div>
      </div>
    </div>
    <!-- <el-alert
        class="el-alert-my"
        type="success">
        <template #title>
          <div class="el-alert-my-txt">
            <span class="tips">提示：</span>
            <span>请选择统计条件查看对应数据，刷新图表数据请点击图表中的刷新按钮。</span>
          </div>
        </template>
</el-alert> -->
    <!-- <div class="statistic-box">
      <div class="item info" @click="navTo('MemberList', { userType: 1 })">
        <div class="val">{{ formData.data.ableUser || 0 }}</div>
        <div class="key">{{ $t('decision.able_user') }}</div>
      </div>
      <div class="item info" @click="navTo('MemberList', { userType: 2 })">
        <div class="val">{{ formData.data.activeUser || 0 }}</div>
        <div class="key">{{ $t('decision.active_user') }}</div>
      </div>
  
    </div> -->
    <Regs :agentCode="formData.valOperator" :devType="formData.valType" :cycle="formData.valCircle"
      :startAt="formData.valTime" class="card-box" />
    <Consume :agentCode="formData.valOperator" :cycle="formData.valCircle" :startAt="formData.valTime"
      class="card-box" />
    <Feedback :agentCode="formData.valOperator" :cycle="formData.valCircle" :startAt="formData.valTime"
      class="card-box" />
    <MonthCardWillExpire :agentCode="formData.valOperator" class="card-box" />
  </div>
</template>

<script>
import { reactive, ref, onMounted, watch } from 'vue'
import Regs from '@/components/charts/regs.vue'
import Consume from '@/components/charts/consume.vue'
import Feedback from '@/components/charts/feedback.vue'
import MonthCardWillExpire from '@/components/tables/MonthCardWillExpire.vue'
import { operators ,cascade_options_direct} from '@/data/default'
import { dayjs } from 'element-plus'
import axios from 'axios'
import i18n from '@/data/i18n/index'
import { navTo } from '@/common/func'
import { isOperator } from '@/data/token'

export default {
  name: 'OperationUsers',
  components: {
    Regs,
    Feedback,
    MonthCardWillExpire,
    Consume
  },
  setup() {
    const t = i18n.global.t
    const valOperator = 0
    const valType = 0
    const valCircle = 1
    //今年1月1号
    const now = new Date()
    const valTime = new Date(`${now.getFullYear()}/01/01 00:00:00`)
    let data = {}
    const types = [
      {
        label: t('device.types.all'),
        value: 0
      },
      {
        label: t('device.types.ebox'),
        value: 1
      }, {
        label: t('device.types.cbox'),
        value: 2
      }, {
        label: t('device.types.pile'),
        value: 3
      }, {
        label: t('device.types.cpbox'),
        value: 4
      }
    ]
    const circles = [
      {
        label: t('decision.cycles.by_year'),
        value: 1
      }, {
        label: t('decision.cycles.by_month'),
        value: 2
      }
    ]
    const render = {
      operators,
      types,
      circles
    }
    const formData = reactive({
      valOperator,
      valType,
      valCircle,
      valTime,
      data,
      valIncome: {},
      props:{
          children:"Children",
          label:"Name",
          value:"Code",
          emitPath:false,
          checkStrictly:true
      },
    })
    const func = {
      async income() {
        let params = {
          startTime: 0,
          endTime: 0,
        }

        // Math.floor(Date.parse(formData.curDataImport.EndAtStr + " 23:59:59") / 1000)
        //new Date(`${now.getFullYear()}/01/01 00:00:00`)
        console.log(dayjs(dayjs(formData.valTime).format("YYYY-01-01 00:00:00")));
        console.log(dayjs(dayjs(formData.valTime).format("YYYY-12-31 59:59:59")).unix());
        console.log(dayjs(formData.valTime).format("YYYY-MM-DD"));
        if (formData.valCircle == 1) {
          console.log(JSON.stringify(dayjs(formData.valTime).format("YYYY-01-01 00:00:00")));
          console.log(JSON.stringify(dayjs(formData.valTime).format("YYYY-12-31 59:59:59")));

          params.startTime = dayjs(dayjs(formData.valTime).format("YYYY-01-01 00:00:00")).unix()
          params.endTime = dayjs(dayjs(formData.valTime).format("YYYY-12-31 59:59:59")).unix()
        } else if (formData.valCircle == 2) {
          console.log(JSON.stringify(dayjs(formData.valTime).format("YYYY-MM-01 00:00:00")));
          console.log(JSON.stringify(dayjs(formData.valTime).format("YYYY-MM-31 59:59:59")));
          params.startTime = dayjs(dayjs(formData.valTime).format("YYYY-MM-01 00:00:00")).unix()
          params.endTime = dayjs(dayjs(formData.valTime).format("YYYY-MM-31 59:59:59")).unix()
        }
        if (formData.valOperator) {
          params.agentcode = formData.valOperator
        }
        let res = await axios.get('/admin/homedata/getmoney', { params })
        console.log(JSON.stringify(res), 20);
        if (res) {
          formData.valIncome.RentMoney = res.List.RentMoney;
          formData.valIncome.CommboMoney = res.List.CommboMoney;
          formData.valIncome.ChargeMoney = res.List.ChargeMoney;
          formData.valIncome.TotalMoney = res.List.TotalMoney;
        }
      }
    }
    const loadData = async () => {
      
      let url = '/admin/analyze/user'
      if (formData.valOperator) {
        url += "?agentCode=" + formData.valOperator
      }
      let res = await axios.get(url)
      formData.data = res
    }
    watch(
      [() => formData.valOperator,],
      async () => {
        func.income()
        loadData()
      },
    )
    watch(
      [() => formData.valCircle, () => formData.valTime],
      async (val1, val2) => {
        func.income()

      }
    )
    onMounted(() => {
      func.income()
      loadData()
    })
    return {
      formData,
      render,
      navTo,
      isOperator,
      cascade_options_direct
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;

      >.title {
        font-size: 1rem;
        font-weight: 500;
      }
    }

    .inp-list {
      display: flex;
      align-items: center;

      >.item {
        margin-right: 3rem;

        .label {
          margin-right: 0.625rem;
        }
      }
    }
  }

  >.el-alert-my {
    margin-top: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;

    .el-alert-my-txt {
      font-size: 0.875rem;

      >.tips {
        font-weight: bold;
      }
    }

    :deep(.el-alert__closebtn) {
      top: 1.25rem;
      font-size: 1rem;
    }
  }

  .statistic-box {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.25rem;

    >.item {
      cursor: pointer;
      width: 24%;
      height: 11rem;
      background-color: #fff;
      box-shadow: $box-shadow;
      border-radius: 0.3rem;
      margin-bottom: 1.33%;
      margin-right: 1.33%;
      color: #fff;
      display: flex;
      flex-direction: column;

      &:nth-child(4n) {
        margin-right: 0;
      }

      >.key {
        width: 100%;
        padding: 1rem 0;
        color: $color-txt;
        background-color: #fff;
        border-bottom-left-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
        font-size: 0.875rem;
      }

      >.val {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 3rem;

        >.sup {
          font-size: 1rem;
          margin-left: 0.625rem;
          padding-top: 1.25rem;
        }
      }

      &.info {
        background-color: $color-info;
      }

      &.success {
        background-color: $color-success;
      }

      &.danger {
        background-color: $color-danger;
      }

      &.warning {
        background-color: $color-warning;
      }
    }
  }
}

@media screen and (max-width:800px) {
  .content {
    .statistic-box {
      justify-content: space-between;

      >.item {
        width: 49%;
        margin-right: 0;
      }
    }

    >.card-box {
      .inp-list {
        flex-wrap: wrap;

        >.item {
          width: 100%;
          margin-right: 0;
          margin-bottom: 1rem;
          display: flex;
          align-items: center;

          .label {
            width: 5rem;
            text-align: right;
          }
        }
      }
    }
  }
}
</style>
