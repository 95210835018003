<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">{{ $t("g.condition") }}</span>
      </div>
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t("g.create_at") }}</span>
          <el-date-picker class="inp" v-model="formData.valTime" value-format="YYYY-MM-DD" size="small" type="daterange"
            unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
            :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
          </el-date-picker>
        </div>
        <div class="item">
          <span class="label">{{ $t('device.name') }}</span>
          <el-input class="inp" size="small" v-model="formData.valName" :placeholder="$t('device.name')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('device.site_name') }}</span>
          <el-input class="inp" size="small" v-model="formData.valSiteName"
            :placeholder="$t('device.site_name')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('device.operate_status') }}</span>
          <el-select class="inp" size="small" v-model="formData.valStatus" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.statuses" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item" >
          <span class="label">{{ $t('g.operator') }}</span>
          <!-- <el-select class="inp" size="small" v-model="formData.valCode" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.operators" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select> -->
          <el-cascader size="small"  style="width: 100%;" :clearable="true" @change="func.opt_agent" v-model="formData.valCode" :props="formData.props" :options="render.options" />
        </div>
        <div class="item">
          <span class="label">关联查下级</span>
          <el-select class="inp" size="small" v-model="formData.isstartwith" :placeholder="$t('g.choose')">
            <el-option label="否" :value="0">
            </el-option>
            <el-option label="是" :value="1">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t('device.online_status') }}</span>
          <el-select class="inp" size="small" v-model="formData.valAlert" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.alerts" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t('device.no') }}</span>
          <el-input class="inp" size="small" v-model="formData.valDevName" :placeholder="$t('device.no')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('device.device_id') }}</span>
          <el-input class="inp" size="small" v-model="formData.valBattaryId"
            :placeholder="$t('device.device_id')"></el-input>
        </div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.search" class="search" type="primary" size="small">{{ $t('g.search') }}</el-button>
      </div>
    </div>
    <div class="card-box data-table">
      <div class="header">
        <span class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize
          }}</span>
        <div class="header-icons">
          <!-- <i @click="func.showAddDataDialog()" class="el-icon-plus header-icon"></i> -->
        </div>
      </div>
      <el-table :data="formData.tableData" :stripe="true" @expand-change="func.expand" style="width: 100%"
        @row-click="func.showDetails" :summary-method="func.getSummaries" show-summary @sort-change="func.sort">
        <el-table-column type="expand">
          <template #default="scope">
            <div class="table-detail">
              <div class="item">{{ $t('device.site_name') }}: {{ scope.row.SiteName }}</div>
              <div class="item">{{ $t('device.life_time') }}: {{ func.toCounterTime(scope.row.LifeTime) }}</div>
              <div class="item">{{ $t('device.update_time') }}: {{ scope.row.UpdateAtStr }}
                {{ $t('device.update_device_last') }}：{{ scope.row.BatId }}</div>
              <div class="item">{{ $t('device.temp') }}: {{ scope.row.Temp }}</div>
              <div class="item">{{ $t('device.meas_power') }}: {{ scope.row.MeasPower }}W</div>
              <div class="item">{{ $t('device.electric_quantity') }}: {{ scope.row.MeasEnergy }}KWH</div>
              <div class="item">{{ $t('device.mac') }}: {{ scope.row.MacAddr }}</div>
              <div class="item">{{ $t('device.coordinate') }}: {{ scope.row.Longitude }},{{ scope.row.Latitude }}</div>
              <div class="item">{{ $t('device.slot_num_all') }}: {{ scope.row.SlotNum }}</div>
              <div class="item">{{ $t('device.slot_num_empty') }}: {{ scope.row.SlotNumEm }}</div>
              <div class="item">{{ $t('device.slot_num_error') }}: {{ scope.row.SlotNumErr }}</div>
              <div class="item">{{ $t('device.address') }}: {{ scope.row.Address }}</div>
              <div class="item">
                <el-button class="btn" @click="func.openSlot(scope.row.DevId, 0)" size="small" :type="'primary'">{{
                  $t('device.open_all_slots') }}</el-button>
                <el-button class="btn" @click="func.restartDev(scope.row.DevId)" size="small" :type="'warning'">{{
                  $t('device.reset') }}</el-button>
                <el-button class="btn" @click="func.operateHeater(scope.row.DevId, 1)" size="small" :type="'primary'"
                  v-if="scope.row.IsHaveHeater">{{ $t('device.open_heater') }}</el-button>
                <el-button class="btn" @click="func.operateHeater(scope.row.DevId, 0)" size="small" :type="'warning'"
                  v-if="scope.row.IsHaveHeater">{{ $t('device.close_heater') }}</el-button>

              </div>

              <div class="item" v-if="!formData.slots[scope.row.Id]">{{ $t('device.slot_loading') }}</div>
              <div class="slots" v-else>
                <div class="slot" v-for="(item, idx) in formData.slots[scope.row.Id]" :key="idx">
                  <div>{{ $t('device.slot_no') }} : {{ item.DevId }}</div>
                  <div class="deviceid">{{ $t('device.device_id') }} : {{ item.BatId }}</div>
                  <div>{{ $t('device.slot_type') }} : {{ item.SlotType ? $t('device.slot_battery') :
                    $t('device.slot_empty') }}</div>

                  <div>{{ $t('device.voltage') }} : {{ item.Voltage }}</div>
                  <div>{{ $t('device.current') }} : {{ item.Current }}</div>
                  <div>SOC : {{ item.Soc }}</div>
                  <div>SOH : {{ item.Soh }}</div>
                  <div>{{ $t('device.slot_warning') }} : {{ item.AlertInfo }}</div>
                  <div>{{ $t('device.slot_status') }} : <span
                      :style="{ color: item.DoorStatus == 1 ? '#E6A23C' : '#67C23A' }">{{
                        item.DoorStatus == 1 ? '打开' : '关闭' }}</span></div>
                  <el-button class="btn" @click="func.toggleSlotStatus(item)" size="small"
                    :type="item.Status ? 'danger' : 'success'">{{ item.Status ? $t('device.disable') :
                      $t('device.enable') }}</el-button>
                  <el-button class="btn" @click="func.openSlot(scope.row.DevId, item.DevId)" size="small"
                    :type="'primary'">{{ $t('device.open_slot') }}</el-button>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="Id" label="#" width="80">
        </el-table-column>
        <el-table-column prop="Name" :label="$t('device.name')" width="140">
        </el-table-column>
        <el-table-column prop="NamePath" min-width="180" label="运营商归属" :show-overflow-tooltip='true'>
        </el-table-column>
        <el-table-column prop="SiteName" :label="$t('device.site_name')" width="140">
        </el-table-column>
        <el-table-column prop="Address" :label="$t('device.address')" width="140">
        </el-table-column>
        <el-table-column prop="MacAddr" :label="$t('device.mac')" width="140">
        </el-table-column>
        <el-table-column prop="DevId" :label="$t('device.no')" width="260">
        </el-table-column>
        <el-table-column prop="AgentCode" v-if="!isOperator()" :label="$t('g.agent_code')" width="100">
        </el-table-column>
        <el-table-column prop="AgentLevel" min-width="260" :label="$t('member.levelname')"> </el-table-column>
        <el-table-column prop="Specs" min-width="200" :label="$t('device.specs')"> </el-table-column>
        <el-table-column prop="ModeNo" min-width="140" :label="$t('device.pattern')"> </el-table-column>
        <el-table-column prop="SlotNum" width="100" :label="$t('device.slot_num_all')">
        </el-table-column>
        <el-table-column prop="SlotNumEm" width="100" :label="$t('device.slot_num_empty')">
        </el-table-column>
        <el-table-column prop="SlotNumBattary" width="100" :label="$t('device.slot_num_bat')">
        </el-table-column>
        <el-table-column prop="SlotNumErr" width="120" :label="$t('device.slot_num_error')" sortable="custom">
        </el-table-column>
        <el-table-column prop="Temp" width="100" label="温度" sortable="custom">
        </el-table-column>
        <el-table-column prop="Deposit" width="120" :label="$t('device.deposit')">
        </el-table-column>
        <el-table-column prop="CountRate" width="120" :label="$t('bill.settle_rate')">
        </el-table-column>
        <el-table-column width="100" :label="$t('device.alert')">
          <template #default="scope">
            <el-tag :type="scope.row.Alert == 0 ? 'success' : 'warning'" effect="dark">
              {{ scope.row.Alert == 0 ? $t('g.no') : $t('g.yes') }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="100" :label="$t('device.online_status')">
          <template #default="scope">
            <el-tag :type="scope.row.IsOnline == 0 ? 'warning' : 'success'" effect="dark">
              {{ scope.row.IsOnline == 0 ? $t('g.no') : $t('g.yes') }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="130" :label="$t('device.status')">
          <template #default="scope">
            <el-tag :type="scope.row.Status == 1 ? 'success' : 'info'" effect="dark">
              {{ scope.row.Status == 1 ? $t('device.statuses.enabled') : $t('device.statuses.disabled') }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="130" :label="$t('device.isrent')">
          <template #default="scope">
            <el-tag :type="scope.row.IsRent == 1 ? 'success' : 'info'" effect="dark">
              {{ scope.row.IsRent == 1 ? $t('device.statuses.enrent') : $t('device.statuses.unrent') }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="130" :label="$t('device.isreplaceable')">
          <template #default="scope">
            <el-tag :type="scope.row.IsReturn == 1 ? 'success' : 'info'" effect="dark">
              {{ scope.row.IsReturn == 1 ? $t('device.statuses.replaceable') : $t('device.statuses.unreplaceable') }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="130" :label="$t('member.is_release')" v-if="isAdmin()">
          <template #default="scope">
            <el-tag :type="scope.row.IsRelease == true ? 'success' : 'info'" effect="dark">
              {{ scope.row.IsRelease == false ? $t('g.no') : $t('g.yes') }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="CreateAtStr" min-width="180" :label="$t('g.create_at')">
        </el-table-column>
        <el-table-column prop="UpdateAtStr" min-width="180" :label="$t('g.update_at')">
        </el-table-column>
        <el-table-column width="140" :label="$t('g.operation')" fixed="right">
          <template #default="scope">
            <el-tag @click="func.rowDblClick(scope.row)">{{ $t('g.edit') }}</el-tag>
            <el-tag @click="func.qrcode(scope.row)" style="margin-left: 10px;">二维码</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
        :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
      </el-pagination>
    </div>
    <el-dialog :title="$t('g.detail')" v-model="formData.dialogDisplayVisible" width="80%" style="margin-top: 0;">
      <el-descriptions border column="3">
        <el-descriptions-item label="Id">{{ formData.curData.Id }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.name')">{{ formData.curData.Name }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.site_name')">
          {{ formData.curData.SiteName }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.no')">{{ formData.curData.DevId }}</el-descriptions-item>
        <el-descriptions-item v-if="!isOperator()" :label="$t('g.agent_code')">{{ formData.curData.AgentCode
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.levelname')">{{ formData.curData.AgentLevel }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.specs')">{{ formData.curData.Specs }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.pattern')">{{ formData.curData.ModeNo }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.mac')">
          {{ formData.curData.MacAddr }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.coordinate')">
          {{ formData.curData.Longitude }},{{ formData.curData.Latitude }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.address')">
          {{ formData.curData.Address }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.deposit')">{{ formData.curData.Deposit }}</el-descriptions-item>
        <el-descriptions-item :label="$t('bill.settle_rate')">{{ formData.curData.CountRate }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.alert')">
          <el-tag :type="formData.curData.Alert == 0 ? 'success' : 'warning'" effect="dark">
            {{ formData.curData.Alert == 0 ? $t('g.no') : $t('g.yes') }}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('device.online_status')">
          <el-tag :type="formData.curData.IsOnline == 0 ? 'warning' : 'success'" effect="dark">
            {{ formData.curData.IsOnline == 0 ? $t('g.no') : $t('g.yes') }}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('device.status')">
          <el-tag :type="formData.curData.Status == 1 ? 'success' : 'info'" effect="dark">
            {{ formData.curData.Status == 1 ? $t('device.statuses.enabled') : $t('device.statuses.disabled') }}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('device.isrent')"> <el-tag
            :type="formData.curData.IsRent == 1 ? 'success' : 'info'" effect="dark">{{ formData.curData.IsRent == 1 ?
              $t('device.statuses.enrent') : $t('device.statuses.unrent') }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('device.isreplaceable')"> <el-tag
            :type="formData.curData.IsReturn == 1 ? 'success' : 'info'" effect="dark">{{ formData.curData.IsReturn == 1
              ?
              $t('device.statuses.replaceable') : $t('device.statuses.unreplaceable') }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('member.is_release')" v-if="isAdmin()"> <el-tag
            :type="formData.curData.IsRelease == true ? 'success' : 'info'" effect="dark">{{ formData.curData.IsRelease
              ==
              false ? $t('g.no') : $t('g.yes') }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('device.life_time')">
          {{ func.toCounterTime(formData.curData.LifeTime) }}</el-descriptions-item>


        <el-descriptions-item :label="$t('device.temp')">
          {{ formData.curData.Temp }}&#8451;</el-descriptions-item>
        <el-descriptions-item :label="$t('device.meas_power')">
          {{ formData.curData.MeasPower }}W</el-descriptions-item>
        <el-descriptions-item :label="$t('device.electric_quantity')">
          {{ formData.curData.MeasEnergy }}KWH</el-descriptions-item>
        <el-descriptions-item :label="$t('device.electric_price')">
          {{ formData.curData.EnergyPrice }}元</el-descriptions-item>

        <el-descriptions-item :label="$t('device.img_dev')" v-if="formData.curData.ImgBox">
          <el-image style="width:300px;height: 300px;" :src="formData.curData.ImgBox"
            :preview-src-list="[formData.curData.ImgBox, formData.curData.ImgStore, formData.curData.ImgStreet]" />
        </el-descriptions-item>
        <el-descriptions-item :label="$t('device.img_site')" v-if="formData.curData.ImgStore">
          <el-image style="width:300px;height: 300px;" :src="formData.curData.ImgStore"
            :preview-src-list="[formData.curData.ImgStore, formData.curData.ImgBox, formData.curData.ImgStreet]" />
        </el-descriptions-item>
        <el-descriptions-item :label="$t('device.img_env')" v-if="formData.curData.ImgStreet">
          <el-image style="width:300px;height: 300px;" :src="formData.curData.ImgStreet"
            :preview-src-list="[formData.curData.ImgStreet, formData.curData.ImgBox, formData.curData.ImgStore]" />
        </el-descriptions-item>
        <el-descriptions-item :label="$t('device.slot_num_all')">
          {{ formData.curData.SlotNum }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.slot_num_empty')">
          {{ formData.curData.SlotNumEm }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.slot_num_error')">
          {{ formData.curData.SlotNumErr }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.update_device_last')">
          {{ formData.UpdateAtLast.BatId }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.create_at')">
          {{ formData.curData.CreateAtStr }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.update_time')">
          {{ formData.curData.UpdateAtStr }}</el-descriptions-item>


      </el-descriptions>
      <el-table :data="formData.slots[formData.curData.Id]" border
        style="margin-top:20px;text-align:center;width:100%;">
        <el-table-column prop="DevId" :label="$t('device.slot_no')" width="60"></el-table-column>
        <el-table-column prop="BatId" :label="$t('device.device_id')" width="260"></el-table-column>
        <el-table-column prop="slot_type" :label="$t('device.slot_type')">
          <template #default="scope">
            {{ scope.row.SlotType ? $t('device.slot_battery') :
              $t('device.slot_empty') }}
          </template>
        </el-table-column>
        <el-table-column prop="Voltage" :label="$t('device.voltage')">
          <template #default="scope">
            {{ scope.row.Voltage / 10 + 'V' }}
          </template>
        </el-table-column>
        <el-table-column prop="Current" :label="$t('device.current')">
          <template #default="scope">
            {{ scope.row.Current / 10 + 'A' }}
          </template>
        </el-table-column>
        <el-table-column prop="Soc" label="SOC"></el-table-column>
        <el-table-column prop="Soh" label="SOH"></el-table-column>
        <el-table-column prop="AlertInfo" :label="$t('device.slot_warning')"></el-table-column>
        <el-table-column prop="Remark" :label="$t('order.remark')"></el-table-column>
        <el-table-column prop="slot_status" :label="$t('device.slot_status')">
          <template #default="scope">
            <span :style="{ color: scope.row.DoorStatus == 1 ? '#E6A23C' : '#67C23A' }">{{ scope.row.DoorStatus == 1 ?
              '打开' : '关闭'
              }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="UpdateAtStr" :label="$t('g.update_at')" width="180"></el-table-column>

      </el-table>
    </el-dialog>
    <el-dialog :title="$t('g.detail')" v-model="formData.dialogVisible" width="60%">
      <el-form label-width="100px" :model="formData.curData">
        <div class="el-form-row line-3">
          <el-form-item :label="$t('device.status')">
            <el-select class="inp" size="small" v-model="formData.curData.Status" :placeholder="$t('g.choose')">
              <el-option v-for="item in render.updateStatuses" :key="item.value" :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('device.isrent')">
            <el-select class="inp" size="small" v-model="formData.curData.IsRent" :placeholder="$t('g.choose')">
              <el-option v-for="item in render.rentStatuses" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('device.isreplaceable')">
            <el-select class="inp" size="small" v-model="formData.curData.IsReturn" :placeholder="$t('g.choose')">
              <el-option v-for="item in render.replaceStatuses" :key="item.value" :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('member.is_release')" v-if="isAdmin()">
            <el-select class="inp" size="small" v-model="formData.curData.IsRelease" :placeholder="$t('g.choose')">
              <el-option v-for="item in render.releaseStatuses" :key="item.value" :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('device.deposit2')">
            <el-input size="small" type="number" v-model="formData.curData.Deposit"></el-input>
          </el-form-item>
          <el-form-item :label="$t('bill.settle_rate')">
            <el-input size="small" type="number" v-model="formData.curData.CountRate"></el-input>
          </el-form-item>
          <el-form-item :label="$t('device.longitude')">
            <el-input size="small" type="number" v-model="formData.curData.Longitude"></el-input>
          </el-form-item>
          <el-form-item :label="$t('device.latitude')">
            <el-input size="small" type="number" v-model="formData.curData.Latitude"></el-input>
          </el-form-item>
          <el-form-item :label="$t('device.electric_price')">
            <el-input size="small" type="number" v-model="formData.curData.EnergyPrice"></el-input>
          </el-form-item>
          <el-form-item :label="$t('rights.agent')" v-if="formData.is_opt">
            <el-cascader size="small"  style="width: 100%;" :clearable="true" v-model="formData.curData.agentCode" :props="formData.props2" :options="render.options" />
          </el-form-item>
          <el-form-item :label="$t('g.operator')"  >
            <el-cascader size="small"  style="width: 100%;" :clearable="true" v-model="formData.curData.AgentPath" :props="formData.props" :options="render.options" />
          </el-form-item>
        </div>
        <div class="el-form-row line-3">
          <el-form-item :label="$t('device.img_dev')">
            <el-upload class="avatar-uploader" name="Img" action="/admin/device/ImgsUp" :multiple="false"
              :show-file-list="false" method="post"
              :data="{ 'id': 'ImgBox', 'DevId': formData.curData.DevId, 'ImgType': 0 }"
              :headers="{ Authorization: token }" :on-error="func.onUploadError" :on-success="func.onUploadSuccess"
              :before-upload="func.onUploadBefore" :http-request="(params) => func.onUpload(params, 0)">
              <img v-if="formData.curData.ImgBox" :src="formData.curData.ImgBox" class="avatar" />
              <el-icon v-else class="avatar-uploader-icon">
                <plus />
              </el-icon>
            </el-upload>
          </el-form-item>
          <el-form-item :label="$t('device.img_site')">
            <el-upload class="avatar-uploader" name="file" :action="uploadUrl" :multiple="false" :show-file-list="false"
              :data="{ 'id': 'ImgStore' }" :headers="{ Authorization: token }" :on-error="func.onUploadError"
              :on-success="func.onUploadSuccess" :before-upload="func.onUploadBefore"
              :http-request="(params) => func.onUpload(params, 1)">
              <img v-if="formData.curData.ImgStore" :src="formData.curData.ImgStore" class="avatar" />
              <el-icon v-else class="avatar-uploader-icon">
                <plus />
              </el-icon>
            </el-upload>
          </el-form-item>
          <el-form-item :label="$t('device.img_env')">
            <el-upload class="avatar-uploader" name="file" :action="uploadUrl" :multiple="false" :show-file-list="false"
              :data="{ 'id': 'ImgStreet' }" :headers="{ Authorization: token }" :on-error="func.onUploadError"
              :on-success="func.onUploadSuccess" :before-upload="func.onUploadBefore"
              :http-request="(params) => func.onUpload(params, 2)">
              <img v-if="formData.curData.ImgStreet" :src="formData.curData.ImgStreet" class="avatar" />
              <el-icon v-else class="avatar-uploader-icon">
                <plus />
              </el-icon>
            </el-upload>
          </el-form-item>
        </div>

      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="func.updateData()">{{ $t('g.btn_confirm') }}</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog class="qrcode" v-model="formData.dialogQrcodeVisible" style="margin-top: 100px;">
      <div>{{ formData.qrcodeMsg }} </div>
      <canvas id="qrcode" style="width:300px;height:300px;border:1px solid black;margin-top: 20px;"></canvas>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, onMounted, computed, nextTick } from 'vue'
import { shortcuts, operators,cascade_options } from '@/data/default'
import { toDay, toCounterTime } from '@/common/func'
import axios from "axios"
import { ElMessage, ElMessageBox } from 'element-plus'
import cfg from '@/common/conf'
import { user, isOperator, isAdmin } from '@/data/token'
import i18n from '@/data/i18n/index'
import QRCode from "qrcode" //引入生成二维码插件
export default {
  name: 'EboxList',
  components: {
  },
  setup() {
    const t = i18n.global.t
    const tableData = []
    const statuses = [
      {
        label: t('device.statuses.all'),
        value: -1
      },
      {
        label: t('device.statuses.enabled'),
        value: 1
      },
      {
        label: t('device.statuses.disabled'),
        value: 0
      }
    ]

    const render = reactive({
      statuses,
      operators,
      updateStatuses: [
        {
          label: t('device.enable'),
          value: 1
        },
        {
          label: t('device.disable'),
          value: 0
        }
      ],
      rentStatuses: [{
        label: t('device.statuses.enrent'),
        value: 1
      }, {
        label: t('device.statuses.unrent'),
        value: 0
      }],
      replaceStatuses: [{
        label: t('device.statuses.replaceable'),
        value: 1
      }, {
        label: t('device.statuses.unreplaceable'),
        value: 0
      }],
      releaseStatuses: [{
        label: t('g.no'),
        value: false
      }, {
        label: t('g.yes'),
        value: true
      }],
      alerts: [
        {
          label: t('device.statuses.all'),
          value: -1
        },
        {
          label: t('device.statuses.online'),
          value: 0
        },
        {
          label: t('device.statuses.offline'),
          value: 1
        }
      ],
      shortcuts,
      options:[]
    })
    const formData = reactive({
      tableData,
      slots: {},
      curData: {},
      valTime: '',
      valStatus: -1,
      valAlert: -1,
      dialogVisible: false,
      dialogDisplayVisible: false,
      dialogQrcodeVisible: false,
      qrcodeMsg: '',
      curPage: 1,
      pageSize: 10,
      total: 0,
      valName: '',
      valCode: 0,
      valDevName: '',
      UpdateAtLast: {},//电池更新最后时间
      sortOpt: { isTempSort: 0, isSlotErrSort: 0 },
      props:{
          children:"Children",
          label:"Name",
          value:"CodePath",
          emitPath:false,
          checkStrictly:true
      },
      props2:{
        children:"Children",
          label:"Name",
          value:"Code",
          emitPath:false,
          checkStrictly:true
      },
      user_info:{},
      is_opt:false,
      isstartwith:1
    })
    const func = {
      getSummaries(options) {
        const { columns, data } = options;

        const sums = [];
        columns.forEach((column, index) => {

          if (index === 0) {
            sums[index] = t("g.total");
            return;
            //index === 22 || index === 24 || index === 28  这是表格第几列需要合计值
            // 例如只需要第3列的值求和，index===3
          } else if (index === 11 || index === 12 || index === 13 || index === 14) {
            // let tmp = column.property.split('.');
            const values = data.map((item) => Number(item[column.property]));
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              // console.log(JSON.stringify(value),23980);
              if (!isNaN(value)) {
                return Math.floor((prev + curr) * 100) / 100;
              } else {
                return prev;
              }
            }, 0);
          }
        });

        return sums;
      },
      showDetails(row, column) {
        if (column && column.label == t('g.operation')) {
          return;
        }
        func.expand(row, [1])
        formData.curData = row;
        func.getImage()
        formData.dialogDisplayVisible = true;
      },
      async operateHeater(devId, type) {
        let params = {
          deviceId: devId,
          conType: type
        }
        if (!devId) {
          return
        }
        await axios.post('/admin/device/heatercontrol', params)
        ElMessage({
          type: 'success',
          message: t('device.cmd_sent'),
        })
      },
      async openSlot(devId, slotId) {
        try {
          await ElMessageBox.confirm(
            t('device.sure_open_slot'),
            t('tips'),
            {
              confirmButtonText: t('confirm'),
              cancelButtonText: t('cancel'),
              type: 'warning',
            }
          )
          // console.log(JSON.stringify(slotId));
          // console.log(JSON.stringify(devId));
          // return; 
          await axios.post('/admin/device/openSlot', {
            devId,
            slotId
          })
          ElMessage({
            type: 'success',
            message: t('device.cmd_sent'),
          })
        } catch (e) {

        }
      },
      async restartDev(devId) {
        try {
          await ElMessageBox.confirm(
            t('device.sure_restart_dev'),
            t('tips'),
            {
              confirmButtonText: t('confirm'),
              cancelButtonText: t('cancel'),
              type: 'warning',
            }
          )
          let res = await axios.post('/admin/device/restartDev', {
            devId
          })
          console.log(JSON.stringify(res));

          if (res.indexOf('成功')) {
            ElMessage({
              type: 'success',
              message: res,
            })
          } else {
            ElMessage({
              type: 'error',
              message: res,
            })
          }

        } catch (e) {

        }
      },
      toCounterTime,
      async toggleSlotStatus(item) {
        let res = await axios.post('/admin/device/disableSlot', {
          slotId: item.Id,
          disableInt: item.Status === 0 ? 0 : 1,
        })
        item.Status = 1 - item.Status
        ElMessage({
          type: 'success',
          message: t('g.success')
        })
      },
      async expand(row, state) {
        if (state.length) {
          const res = await axios.get("/admin/box/slot?eboxId=" + row.Id)
          formData.slots[row.Id] = res
          let max = 0
          res.map(item => {
            item.UpdateAtStr = toDay(item.UpdateAt)
            // if (item.UpdateAt > max) {
            //   max = item.UpdateAt
            //   item.UpdateAtStr = toDay(item.UpdateAt)
            //   formData.UpdateAtLast = item;

            // }
          })
          // console.log(JSON.stringify(formData.UpdateAtLast));

        }
      },
      sort(val) {
        let obj = { null: 0, descending: -1, ascending: 1 }
        if (val.prop == 'Temp') {
          console.log(obj[val.order])
          formData.sortOpt.isSlotErrSort = 0
          formData.sortOpt.isTempSort = obj[val.order]
        } else if (val.prop == 'SlotNumErr') {
          formData.sortOpt.isTempSort = 0
          formData.sortOpt.isSlotErrSort = obj[val.order]
        }
        func.loadData()
      },
      async getImage() {
        let res = await axios.post('/admin/device/imgsGet', {
          DevId: formData.curData.DevId
        })
        if (res) {
          if (res.devImg) {
            formData.curData.ImgBox = 'data:image/bmp;base64,' + res.devImg;
          }
          if (res.stationImg) {
            formData.curData.ImgStore = 'data:image/bmp;base64,' + res.stationImg;
          }
          if (res.streetImg) {
            formData.curData.ImgStreet = 'data:image/bmp;base64,' + res.streetImg;
          }
        }
      },
      async onUpload(data, type = 0) {
        let fd = new FormData();
        let headers = {
          'Content-Type': 'multipart/form-data'
        }
        fd.append('Img', data.file)
        fd.append('DevId', formData.curData.DevId)
        fd.append('ImgType', type)
        console.log(fd.get('Img'));
        let res = await axios.post('/admin/device/imgsUp', fd, { headers })
        func.getImage()
      },
      onUploadBefore() {
        console.log("onUploadBefore")
      },
      onUploadError() {
        ElMessage({
          type: 'error',
          message: t('g.upload_failed')
        })
      },
      qrcode(row) {
        formData.dialogQrcodeVisible = true;

        console.log(JSON.stringify(row));
        let QRCodeMsg = 'http://' + row.AgentCode.toLowerCase() + '.wxrule.smart2charge.com/device?id=' + row.DevId;
        // return
        let opts = {
          errorCorrectionLevel: "H",//容错级别
          type: "image/png",//生成的二维码类型
          quality: 0.3,//二维码质量
          margin: 5,//二维码留白边距
          width: 300,//宽
          height: 300,//高
          text: QRCodeMsg,//二维码内容
          color: {
            dark: "#333333",//前景色
            light: "#fff"//背景色
          }
        };

        formData.qrcodeMsg = QRCodeMsg
        nextTick(() => {
          let msg = document.getElementById("qrcode");
          QRCode.toCanvas(msg, QRCodeMsg, opts, function (error) {
            console.log(error)
          });
        })



      },
      onUploadSuccess(res) {
        console.log("上传结果", res)
        if (res.Code != 200) {
          return ElMessage({
            type: 'error',
            message: res.Msg
          })
        }
        const { id, url } = res.Data
        formData.curData[id] = url
      },
      search() {
        console.log('formData', formData)
        func.loadData()
      },
      opt_agent(val1,val2){
        console.log(val1,val2)
      },
      rowDblClick(row, column, e) {
        console.log('rowDblClick', row, column, e) 
        formData.curData = row
        formData.curData.AgentPath = row.CodePath
        formData.curData.agentCode =  row.AgentCode
        console.log('dasdad',row)
        
        func.getImage()
        formData.dialogVisible = true
      },
      pageChange(val) {
        console.log('page change', val)
        func.loadData(val)
      },
      showAddDataDialog() {
        formData.curData = {
        }
        formData.dialogVisible = true
      },
      async updateData() {
        console.log("updateOrCreate Data", formData.curData)
        if (typeof (formData.curData.Status) == 'undefined') {
          ElMessage({
            type: 'error',
            message: t("device.please_choose_status")
          })
          return
        }
        try {
          const params = {
            id: formData.curData.Id,
            // imgBox: formData.curData.ImgBox,
            // imgStore: formData.curData.ImgStore,
            // imgStreet: formData.curData.ImgStreet,
            status: formData.curData.Status,
            deposit: formData.curData.Deposit,
            countRate: formData.curData.CountRate,
            longitude: formData.curData.Longitude,
            latitude: formData.curData.Latitude,
            isrent: formData.curData.IsRent,
            isreturn: formData.curData.IsReturn,
            energyPrice: formData.curData.EnergyPrice,
            agentPath:formData.curData.AgentPath,
            agentCode:formData.curData.agentCode,
          }
          if (formData.curData.password) {
            params.password = formData.curData.password
          }
          if (formData.curData.IsRelease) {
            params.isrelease = formData.curData.IsRelease
          }
          await axios.put('/admin/box', params)
        } catch (e) {
        }
        formData.dialogVisible = false
        func.loadData(formData.curPage)
      },
      async get_options(){
        render.options = await cascade_options()
        console.log(render.options,'dad121212')
      },
      async loadData(page = 1) {
        try {
          formData.tableData = []
          formData.curPage = page
          let params = {
            page,
            type: 1,
          }
          if (formData.valStatus != -1) {
            params.status = formData.valStatus
          }
          if (formData.valAlert != -1) {
            params.alert = formData.valAlert
          }
          if (formData.valName) {
            params.name = formData.valName
          }
          if (formData.valSiteName) {
            params.siteName = formData.valSiteName
          }
          if (formData.valCode) {
            params.code = formData.valCode
          }
          if (formData.valBattaryId) {
            params.batId = formData.valBattaryId
          }
          if (formData.valTime) {
            params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
            params.endAt = Math.floor(Date.parse(formData.valTime[1] + " 23:59:59") / 1000)
          }
          if (formData.valDevName) {
            params.devName = formData.valDevName
          }
          params.isstartwith = formData.isstartwith
          params.isTempSort = formData.sortOpt.isTempSort
          params.isSlotErrSort = formData.sortOpt.isSlotErrSort
          let res = await axios.get(`/admin/box`, {
            params
          })
          formData.total = res.Total
          res.List.map((item) => {
            item.CreateAtStr = toDay(item.CreateAt)
            item.UpdateAtStr = toDay(item.UpdateAt)
            item.SlotNumBattary = (item.IsOnline == 1 || item.SlotNumEm > 0) ? item.SlotNum - item.SlotNumEm : 0
            // if (item.DevType != 'null') {
            //   item.specsStr = JSON.parse(item.DevType) && JSON.parse(item.DevType).Specs
            //   item.ModeNoStr = JSON.parse(item.DevType) && JSON.parse(item.DevType).ModeNo
            // }
            // if (item.OperConf) {
            //   item.hasHeater = JSON.parse(item.OperConf) && JSON.parse(item.OperConf).IsHaveHeater
            // }
          })
          formData.tableData = res.List
          formData.pageSize = res.PageSize
        } catch (e) {
          console.log("loadData", e)
        }
      }
    }
    onMounted(() => {
      func.loadData()
      func.get_options()
      if(localStorage.getItem('user_info')){
        formData.user_info = JSON.parse(localStorage.getItem('user_info'))
        if(formData.user_info.RoleId == 14 || formData.user_info.RoleId == 25 || formData.user_info.RoleId == 18){
          formData.is_opt = true
        }
      }
    })
    return {
      func,
      render,
      formData,
      uploadUrl: cfg.uploadUrl,
      token: user.token,
      isOperator,
      isAdmin
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;

  .table-detail {
    display: flex;
    flex-direction: column;
    margin-right: 9.375rem;
    padding-left: 4rem;
    padding-top: 1rem;

    .slots {
      margin-top: 1rem;

      .slot {
        display: flex;
        margin-bottom: 0.5rem;

        >div {
          width: 9rem;

          &:first-child {
            width: 6rem;
          }
        }

        .deviceid {
          width: 11rem;

        }
      }
    }

    >.item {
      margin-right: 0.625rem;
      display: flex;
      align-items: center;
      margin-bottom: 0.625rem;
      color: #333333;

      >img {
        margin-left: 0.625rem;
        width: 4rem;
        height: 3rem;
        object-fit: cover;
      }
    }
  }

  .boximg {
    width: 4rem;
    height: 3rem;
    object-fit: cover;
  }

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;

      >.title {
        font-size: 1rem;
        font-weight: 500;
      }

      >.header-icons {
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;

        .header-icon {
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color: #969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }

    >.btn-ct {
      text-align: right;

      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }

    .inp-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: left;

      >.item {
        width: 33%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;

        .label {
          margin-right: 0.625rem;
          width: 4.375rem;
        }

        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }

        .inp {
          flex: 1;
        }
      }
    }
  }

  >.el-alert-my {
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;

    .el-alert-my-txt {
      font-size: 0.875rem;

      >.tips {
        font-weight: bold;
      }
    }

    :deep(.el-alert__closebtn) {
      top: 1.25rem;
      font-size: 1rem;
    }
  }

  // >.data-table {
  //   :deep(.el-table) {
  //     .cell {
  //       white-space: nowrap;
  //       text-overflow: ellipsis;
  //     }

  //     .el-table__row {
  //       cursor: pointer;
  //     }
  //   }
  // }

  >.pagination {
    text-align: right;
    margin-top: 1.25rem;
  }

  .el-form-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    :deep(.el-form-item) {
      width: 49%;
    }

    &.line-3 {
      :deep(.el-form-item) {
        min-width: 32%;
      }
    }

    .inp {
      width: 100%;
    }

    .avatar-uploader {
      :deep(.el-upload) {
        border: 1px dashed #d9d9d9;
        border-radius: 0.375rem;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        &:hover {
          border-color: #409eff;
        }
      }
    }

    .avatar-uploader-icon {
      font-size: 1.75rem;
      color: #8c939d;
      width: 11rem;
      height: 9rem;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .avatar {
      width: 11rem;
      height: 9rem;
      display: block;
      object-fit: cover;
    }
  }

  .el-select-my {
    text-align: left;
    display: block;
  }

  :deep(.m-dialog) {
    max-width: 70rem;
    margin-top: 0;
  }
}

@media screen and (max-width:800px) {
  .content {
    >.card-box {
      .inp-list {
        >.item {
          width: 100%;
        }
      }
    }
  }
}
</style>
