import { reactive } from 'vue'
import storage from "@/common/storage"
import { loadUserMenu } from './menu'
import { OPERATOR_ROLE_ID,SITE_MANAGER_ROLE_ID,ADMIN_ROLE_ID } from "./default"
const key = "dandelion-token"
export const user = reactive({
  name:"",
  id:"",
  token:"",
  type:"",
  roleId:0,
  isMch:0,
  AgentCode:''
})

export const isOperator = ()=>{
  //return (user.type == 'operator' || user.roleId == SITE_MANAGER_ROLE_ID)&&(user.id != 64)
  return user.AgentCode != ''
}

export const isPath = ()=>{
  let type = false
  if(user.roleId == 14 || user.roleId == 25 || user.roleId == 26 || user.roleId == 18){
    type = true
  }else{
    type = false
  }
  return type
}

export const showOperator = ()=>{
  return user.id == 64
}

export const isAdmin = ()=>{
  return user.type == 'admin'
}

export const setUser = (token,id,name,roleId,isMch,AgentCode,save = true)=>{
  user.token = token
  user.id = id
  user.name = name
  user.isMch = isMch
  user.roleId = roleId
  user.AgentCode = AgentCode
  if(roleId == OPERATOR_ROLE_ID){
    user.type = "operator"
  }else if(roleId == ADMIN_ROLE_ID){
    user.type = "admin"
  }
  if(save)storage.set(key,user)
  loadUserMenu(user.id)
}


//load user 
const userData = storage.get(key)
if(userData){
  setUser(userData.token,userData.id,userData.name,userData.roleId,userData.isMch,userData.AgentCode,false)
}
// console.log(JSON.stringify(user));