<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">{{ $t("g.condition") }}</span>
      </div>
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t("g.create_at") }}</span>
          <el-date-picker class="inp" v-model="formData.valTime" value-format="YYYY-MM-DD" size="small" type="daterange"
            unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
            :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
          </el-date-picker>
        </div>

        <!-- <div class="item" v-if="!isOperator()">
          <span class="label">{{ $t('g.operator') }}</span>
          <el-select class="inp" size="small" v-model="formData.valOperator" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.operators" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div> -->
        <div class="item" >
          <span class="label">{{ $t('g.operator') }}</span>
          <el-cascader size="small"  style="width: 100%;flex: 1;" :clearable="true" v-model="formData.valOperator" :props="formData.props" :options="render.cascade_options_direct" />
        </div>
        <div class="item">
          <span class="label">关联查下级</span>
          <el-select class="inp" size="small" v-model="formData.IsStartWith" :placeholder="$t('g.choose')">
            <el-option label="否" :value="0">
            </el-option>
            <el-option label="是" :value="1">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t("bill.nickname") }}</span>
          <el-input class="inp" size="small" v-model="formData.valTel"
            :placeholder="$t('bill.user_nickname')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t("member.all_expire") }}</span>
          <el-date-picker class="inp" v-model="formData.CommTime" value-format="YYYY-MM-DD" size="small"
            type="daterange" unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
            :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
          </el-date-picker>
        </div>
        <div class="item">
          <span class="label">{{ $t("member.IdName") }}</span>
          <el-input class="inp" size="small" v-model="formData.valIdName" :placeholder="$t('member.IdName')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t("member.mobile") }}</span>
          <el-input class="inp" size="small" v-model="formData.valPhoneNum"
            :placeholder="$t('member.mobile')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('member.user_status') }}</span>
          <el-select class="inp" size="small" v-model="formData.valUserStatus" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.userStatuses" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t('member.order_status.name') }}</span>
          <el-select class="inp" size="small" v-model="formData.valOrderStatus" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.orderStatuses" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t('device.device_id') }}</span>
          <el-input class="inp" size="small" v-model="formData.valBattaryId"
            :placeholder="$t('device.device_id')"></el-input>
        </div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.search" class="search" type="primary" size="small">{{ $t('g.search') }}</el-button>
      </div>
    </div>
    <div class="card-box data-table">
      <div class="header">
        <span class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize
          }}</span>
        <div class="header-icons">
          <!-- <i @click="func.loadData()" class="el-icon-download header-icon"></i> -->
          <span @click="func.importData()" style="color:#409eff;margin-right: 10px;"
            v-if="!isEnVersion.status">导入旧数据</span>
          <el-icon @click="func.refresh()" class="header-icon">
            <Refresh />
          </el-icon>
        </div>
      </div>
      <el-table :data="formData.tableData" height="420" style="width: 100%" @row-click.self="func.showDetails"
        @sort-change="func.colChange">
        <el-table-column prop="Id" :label="$t('bill.user_id')" width="80" fixed>
        </el-table-column>
        <el-table-column prop="Nickname" m :label="$t('bill.nickname')" width="100" fixed>
        </el-table-column>
        <el-table-column prop="Money" width="100" :label="$t('member.balance')">
        </el-table-column>
        <el-table-column prop="AgentCode" v-if="!isOperator()" width="80" :label="$t('g.agent_code')">
        </el-table-column>
        <el-table-column prop="NamePath"  min-width="240" label="运营商归属" :show-overflow-tooltip='true'></el-table-column>
        <el-table-column prop="Mobile" width="120" :label="$t('member.mobile')"></el-table-column>
        <el-table-column prop="IdName" width="120" :label="$t('member.IdName')">
        </el-table-column>
        <el-table-column prop="UserComboInfo.RemainDay" width="120" :label="$t('g.residue_days')">
        </el-table-column>
        <el-table-column prop="EndAtStr" width="180" :label="$t('member.expire')" sortable="custom">
          <template #default="scope">
            {{ scope.row.EndAtStr }}
          </template>
        </el-table-column>
        <el-table-column prop="AllEndAt" width="180" :label="$t('member.all_expire')">
          <template #default="scope">
            {{ scope.row.AllEndAt }}
          </template>
        </el-table-column>
        <el-table-column width="110" :label="$t('member.is_expire')">
          <template #default="scope">
            {{ scope.row.UserComboInfo ? ((scope.row.UserComboInfo.Status == 0) ? $t('g.yes') : $t('g.no')) : '' }}
          </template>
        </el-table-column>
        <el-table-column prop="UserComboInfo.RemainTimes" width="80" :label="$t('g.residue_degree')">
        </el-table-column>

        <el-table-column prop="UserCoupon.DayValue" width="100" :label="$t('member.coupon_day')">
        </el-table-column>
        <el-table-column prop="UserCoupon.MoneyValue" width="100" :label="$t('member.coupon_money')">
        </el-table-column>


        <el-table-column width="80" :label="$t('member.avatar')">
          <template #default="scope">
            <img class="avatar" :src="scope.row.Avatar" />
          </template>
        </el-table-column>
        <el-table-column prop="DevModeNo" min-width="180" :label="$t('device.pattern')">
        </el-table-column>
        <el-table-column prop="CreatedAtStr" min-width="180" :label="$t('g.create_at')">
        </el-table-column>
        <el-table-column prop="CurMonthOrderAmount" width="100" :label="$t('member.cur_month_order_amount')">
        </el-table-column>
        <el-table-column prop="CurMonthCost" width="100" :label="$t('member.cur_month_cost')">
        </el-table-column>
        <el-table-column :width="!isEnVersion.status ? 220 : 300" :label="$t('g.operation')" fixed="right">
          <template #default="scope">

            <el-tag @click="func.showBindDialog(scope.row)" size="large"
              :type="scope.row.UserBatteryIds == '' ? 'info' : 'success'" :disabled="false">{{ $t('member.bind_battery')
              }}</el-tag>
            <el-dropdown style="margin-left: 0.5rem;" :hide-on-click="false">
              <el-button type="primary">
                {{ $t('member.more_actions') }}<el-icon class="el-icon--right"><arrow-down /></el-icon>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="func.showClearMobile(scope.row)" :disabled="!scope.row.IsSmsVerify"><el-tag
                      :type="scope.row.IsSmsVerify == false ? 'info' : 'success'">{{ $t('member.clear_mobile')
                      }}</el-tag></el-dropdown-item>
                  <el-dropdown-item @click="func.showunBindDialog(scope.row)"
                    :disabled="!scope.row.UserBatteryIds"><el-tag
                      :type="scope.row.UserBatteryIds == '' ? 'info' : 'success'">{{ $t('member.unbind_battery')
                      }}</el-tag></el-dropdown-item>
                  <el-dropdown-item @click="func.returnBattery(scope.row)" :disabled="!scope.row.UserBatteryIds"><el-tag
                      :type="scope.row.UserBatteryIds == '' ? 'info' : 'success'">退租(还押金)</el-tag></el-dropdown-item>
                  <el-dropdown-item @click="func.toggleTester(scope.row)"> <el-tag
                      :type="scope.row.IsTest == 0 ? 'warning' : 'success'">{{ scope.row.IsTest == 0 ?
                        $t('member.maintainer_set') : $t('member.maintainer_unset') }}</el-tag></el-dropdown-item>
                  <el-dropdown-item @click="func.toggleVip(scope.row)"> <el-tag
                      :type="scope.row.IsVIP == false ? 'warning' : 'success'">{{ scope.row.IsVIP == false ?
                        $t('g.fei') + $t('member.is_vip') : $t('member.is_vip') }}</el-tag></el-dropdown-item>
                  <el-dropdown-item @click="func.toggleFree(scope.row)"><el-tag
                      :type="scope.row.IsFree == 1 ? 'warning' : 'success'">{{ scope.row.IsFree == 0 ?
                        $t('member.freeuser_set') : $t('member.freeuser_unset') }}</el-tag></el-dropdown-item>
                  <el-dropdown-item @click="func.toggleDisable(scope.row)"> <el-tag
                      :type="scope.row.IsDisable == true ? 'warning' : 'success'">{{ scope.row.IsDisable == true ?
                        $t('expenses.disable') : $t('expenses.enable') }}</el-tag></el-dropdown-item>
                  <!-- <el-dropdown-item @click="func.rentOut(scope.row)" :disabled="!scope.row.UserComboInfo?.AllEndAt">
                    <el-tag :type="scope.row.UserComboInfo?.AllEndAt ? 'success' : 'info'">{{
                      $t('member.clear_order_battery')
                    }}</el-tag></el-dropdown-item> -->
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <!-- <el-tag style="margin-left: 1rem;"
                  @click="func.showBindDialog(scope.row)"
                  :type="scope.row.UserBatteryIds==''?'info':'success'"
                  
                >{{$t('member.bind_battery')}}</el-tag>
                <el-tag style="margin-left: 1rem;"
                  @click="func.showunBindDialog(scope.row)"
                  :type="scope.row.UserBatteryIds==''?'info':'success'"
                  
                >{{$t('member.unbind_battery')}}</el-tag>
                <el-tag
                  style="margin-top: 0.5rem;"
                  @click="func.toggleTester(scope.row)"
                  :type="scope.row.IsTest==0?'warning':'success'"
                >{{scope.row.IsTest==0?$t('member.maintainer_set'):$t('member.maintainer_unset')}}</el-tag>
                <el-tag
                  style="margin-left: 1rem;"
                  @click="func.toggleFree(scope.row)"
                  :type="scope.row.IsFree==1?'warning':'success'"
                >{{scope.row.IsFree==0?$t('member.freeuser_set'):$t('member.freeuser_unset')}}</el-tag>
                <el-tag
                  style="margin-left: 1rem;"
                  @click="func.toggleDisable(scope.row)"
                  :type="scope.row.IsDisable==true?'warning':'success'"
                >{{scope.row.IsDisable==true?$t('expenses.disable'):$t('expenses.enable')}}</el-tag> -->
          </template>
        </el-table-column>

      </el-table>
    </div>
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
        :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
      </el-pagination>
    </div>
    <el-dialog :title="$t('g.detail')" v-model="formData.dialogDisplayVisible" width="80%">
      <el-descriptions border>
        <el-descriptions-item :label="$t('bill.user_id')">{{ formData.curData.Id }}</el-descriptions-item>
        <el-descriptions-item :label="$t('bill.nickname')">{{ formData.curData.Nickname }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.balance')">{{ formData.curData.Money }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.agent_code')" v-if="!isOperator()">{{ formData.curData.AgentCode
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.mobile')">{{ formData.curData.Mobile }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.IdNum')"> <el-tag v-if="formData.curData.IdNum"
            @click="func.checkIdCardImage(formData.curData)">{{ formData.curData.IdNum
            }}</el-tag></el-descriptions-item>
        <el-descriptions-item :label="$t('member.IdName')">{{ formData.curData.IdName }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.IdTime')">{{ formData.curData.IdTime }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.residue_degree')">{{
          formData.curData.UserComboInfo ? formData.curData.UserComboInfo.RemainTimes : '' }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.expire')">{{ formData.curData.EndAtStr }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.all_expire')">{{ formData.curData.AllEndAt }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.num_expired')">{{ formData.curData.TimesEndAtStr
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.is_expire')"> {{
          formData.curData.UserComboInfo ? ((formData.curData.UserComboInfo.Status == 0) ? $t('g.yes') : $t('g.no')) :
            ''
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.residue_days')">{{
          formData.curData.UserComboInfo ? formData.curData.UserComboInfo.RemainDay : '' }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.coupon_day')">{{
          formData.curData.UserCoupon ? formData.curData.UserCoupon.DayValue : '' }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.coupon_money')">{{
          formData.curData.UserCoupon ? formData.curData.UserCoupon.MoneyValue : '' }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.device_id')">{{ formData.curData.UserBatteryIds
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('receive_list.user_type') + 1">{{
          formData.curData.IsTest == 0 ? $t('member.maintainer_set') : $t('member.maintainer_unset')
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('receive_list.user_type') + 2">{{
          formData.curData.IsFree == 0 ? $t('member.freeuser_set') : $t('member.freeuser_unset')
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.status')">{{ formData.curData.IsDisable == true ?
          $t('expenses.disable') :
          $t('expenses.enable') }}</el-descriptions-item>

        <el-descriptions-item :label="$t('member.is_vip')">{{
          formData.curData.IsVIP == true ?
            $t('g.yes') :
            $t('g.no') }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('device.pattern')">{{ formData.curData.DevModeNo }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.create_at')">{{ formData.curData.CreatedAtStr }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.cur_month_order_amount')">{{ formData.curData.CurMonthOrderAmount
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.cur_month_cost')">{{ formData.curData.CurMonthCost
          }}</el-descriptions-item>
        <el-descriptions-item label="暂存状态">{{
          formData.curData.UserStage?.LastStageStatus == 1 ? '暂存中' : '无' }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.stage_day')">{{
          formData.curData.UserStage ? formData.curData.UserStage.StagedDay : '' }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.staging_day')">{{
          formData.curData.UserStage ? formData.curData.UserStage.StagingDay : '' }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.stage_month_num')">{{
          formData.curData.UserStage ? formData.curData.UserStage.MonthStagedNum : "" }}</el-descriptions-item>
        <el-descriptions-item label="已激活">{{
          formData.curData.PullGain?.ActivatedNum }}</el-descriptions-item>
        <el-descriptions-item label="现金到账(元)">{{
          formData.curData.PullGain?.IncomedAllMoney }}</el-descriptions-item>
        <el-descriptions-item label="已到账(天)">{{
          formData.curData.PullGain?.IncomedCouponDays }}</el-descriptions-item>
        <el-descriptions-item label="已邀请">{{
          formData.curData.PullGain?.InvitedNum }}</el-descriptions-item>
        <el-descriptions-item label="余额到账(元)">{{
          formData.curData.InviteNewMoney
          || 0 }}</el-descriptions-item>
        <el-descriptions-item label="未到账(天)">{{
          formData.curData.PullGain?.IncomeAllCouponDays - formData.curData.PullGain?.IncomedCouponDays
          || 0 }}</el-descriptions-item>
        <el-descriptions-item label="邀请总金额">{{
          formData.curData.PullGain?.IncomeAllMoney }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.avatar')">
          <img class="avatar" :src="formData.curData.Avatar" /></el-descriptions-item>
      </el-descriptions>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="default" @click="formData.dialogDisplayVisible = false">{{ $t('g.btn_cancel') }}</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog :title="$t('member.unbind_battery')" v-model="formData.unbindDialogVisible" width="60%">
      <div class="bound-info">
        <div class="item">{{ $t('bill.nickname') }}:{{ formData.curData.Nickname }}</div>
        <div class="item">{{ $t('member.bound_battery_no') }}:{{ formData.curData.UserBatteryIds }}</div>
        <div class="item"><el-switch v-model="formData.adBindInfo.IsClearCommbo" size="large" active-text="清空套餐"
            inactive-text="保留套餐" active-value="true" inactive-value="false" /></div>
      </div>
      <el-form label-width="80px" :model="formData.curData" v-if="formData.curData.UserBatteryIds">
        <div>
          <span style="margin-right: 30px;">{{ $t('member.unBindBatteryNum') }}</span>
          <el-select class="inp" size="small" v-model="formData.adBindInfo.oldDevId" :placeholder="$t('g.choose')"
            style="width:300px">
            <el-option v-for="item in formData.devIdList" :value="item.value" :label="item.value">
            </el-option>
          </el-select>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="default" @click="formData.unbindDialogVisible = false">{{ $t('g.btn_cancel') }}</el-button>
          <el-button type="primary" @click="func.bindBattery('Unbind')">{{ $t('g.btn_confirm') }}</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog :title="$t('member.bind_battery')" v-model="formData.dialogVisible" width="60%">
      <div class="bound-info">
        <div class="item">{{ $t('bill.nickname') }}:{{ formData.curData.Nickname }}</div>
        <div class="item">{{ $t('member.bound_battery_no') }}:{{ formData.curData.UserBatteryIds }}</div>
        <div class="item"><el-switch v-model="formData.adBindInfo.IsForceExBind" size="large" active-text="强制换绑"
            inactive-text="正常换绑" active-value="true" inactive-value="false" /></div>
      </div>

      <el-form label-width="80px" :model="formData.curData" v-if="formData.curData.UserBatteryIds">
        <div>
          <span style="margin-right: 30px;">{{ $t('member.badBatteryNum') }}</span>
          <el-select class="inp" size="small" v-model="formData.adBindInfo.oldDevId" :placeholder="$t('g.choose')"
            style="width:300px">
            <el-option v-for="item in formData.devIdList" :value="item.value" :label="item.value">
            </el-option>
          </el-select>
        </div>
        <div style="margin-top: 30px;">

          <span style="margin-right: 30px;">{{ $t('member.goodBatteryNum') }}</span>
          <el-input style="width:300px" label-width="240px" size="small"
            v-model="formData.adBindInfo.newDevId"></el-input>

        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="default" @click="formData.dialogVisible = false">{{ $t('g.btn_cancel') }}</el-button>
          <el-button type="primary" @click="func.bindBattery('ExBind')">{{ $t('g.btn_confirm') }}</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog :title="$t('member.IdCardimg')" v-model="formData.dialogImageVisible" width="60%">
      <el-image :src="formData.idCardFrontImgUrl"
        :preview-src-list="[formData.idCardFrontImgUrl, formData.idCardBackImgUrl]" style="width:300px;height: 400px;"
        alt="图片缺失" fit="cover" />
      <el-image :src="formData.idCardBackImgUrl"
        :preview-src-list="[formData.idCardBackImgUrl, formData.idCardFrontImgUrl]"
        style="width:300px;height: 400px;margin-left: 30px;" alt="图片缺失" fit="cover" />
      <template #footer>
        <span class="dialog-footer">
          <el-button type="default" @click="formData.dialogImageVisible = false">{{ $t('g.btn_cancel') }}</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="formData.dialogImportVisible" class="import-dialog">
      <div class="search">
        <el-input placeholder="输入手机号码" style="width: 240px" v-model="formData.importPhoneNum"></el-input> <el-button
          style="margin-left: 20px;" type="primary" @click="func.searchFromPhone">搜索</el-button>
      </div>
      <div class="dlg-content">
        <div> <el-descriptions border column=1 title="原用户数据">
            <el-descriptions-item label="姓名">{{ formData.curData.IdName }}</el-descriptions-item>
            <el-descriptions-item label="手机">{{ formData.curData.Mobile }}</el-descriptions-item>
            <el-descriptions-item label="身份证号">{{ formData.curData.IdNum }}</el-descriptions-item>
            <el-descriptions-item label="余额"> <el-input :value="formData.curData.Money"
                disabled></el-input></el-descriptions-item>
            <el-descriptions-item label="套餐有效期"> <el-input :value="formData.curData.AllEndAt" disabled
                style="border:none"></el-input></el-descriptions-item>
            <el-descriptions-item label="ID" v-if="formData.importDataList.length > 1">
              <el-select v-model="formData.selectId" placeholder="Select" @change="func.changePhoneId" size="large"
                style="width: 240px">
                <el-option v-for="item in formData.importDataList" :key="item.Id" :label="item.Id" :value="item.Id" />
              </el-select>
            </el-descriptions-item>
            <el-descriptions-item label="ID" v-else>{{ formData.curData.Id }}</el-descriptions-item>
            <el-descriptions-item label="创建时间">{{ formData.curData.CreatedAtStr }}</el-descriptions-item>
          </el-descriptions></div>
        <div> <el-descriptions border style="margin-left: 20px;" column=1 title="导入用户数据">
            <el-descriptions-item label="姓名">
              <span v-if="formData.curDataImport.Name">{{ formData.curDataImport.Name }}<el-icon
                  :color="formData.compare.name ? '#409EFC' : '#c45656'" size="16" style="margin-left: 10px;">
                  <CircleCheckFilled v-if="formData.compare.name" />
                  <CircleCloseFilled v-else />
                </el-icon></span></el-descriptions-item>
            <el-descriptions-item label="手机">
              <span v-if="formData.curDataImport.PhoneNum">{{ formData.curDataImport.PhoneNum }}<el-icon
                  :color="formData.compare.phone ? '#409EFC' : '#c45656'" size="16" style="margin-left: 10px;">
                  <CircleCheckFilled v-if="formData.compare.phone" />
                  <CircleCloseFilled v-else />
                </el-icon></span></el-descriptions-item>
            <el-descriptions-item label="身份证号"><span v-if="formData.curDataImport.IdNum">{{ formData.curDataImport.IdNum
                }}
                <el-icon :color="formData.compare.IdNum ? '#409EFC' : '#c45656'" size="16" style="margin-left: 10px;">
                  <CircleCheckFilled v-if="formData.compare.IdNum" />
                  <CircleCloseFilled v-else />
                </el-icon></span></el-descriptions-item>
            <el-descriptions-item label="余额">
              <el-input placeholder="请输入余额" v-model="formData.curDataImport.Money"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label="套餐有效期">
              <el-input placeholder="格式如：2024-03-11" v-model="formData.curDataImport.EndAtStr"></el-input>
            </el-descriptions-item>
          </el-descriptions>

        </div>

      </div>
      <div class="update" style="margin-top: 30px;"><el-button type="primary"
          @click="func.updateImport(formData.selectId)">更新数据</el-button></div>
    </el-dialog>

  </div>
</template>

<script>
import { reactive, onMounted, VueElement, nextTick } from 'vue'
import axios from 'axios'
import { operators, updateOperators, shortcuts,cascade_options_direct } from '@/data/default'
import { toDay } from "@/common/func"
import { ElMessage, ElMessageBox, ElLoading } from 'element-plus'
import i18n from '@/data/i18n/index'
import { useRoute } from 'vue-router'
import { isOperator } from '@/data/token'
import storage from '@/common/storage'

export default {
  name: 'MemberList',
  setup() {
    const t = i18n.global.t
    const route = useRoute()
    const userType = route.params.userType || 0
    const tableData = []
    const statuses = [
      {
        label: t('member.statuses.all'),
        value: 2
      },
      {
        label: t('member.statuses.success'),
        value: 1
      },
      {
        label: t('member.statuses.failed'),
        value: 0
      }
    ]
    const userStatuses = [
      {
        label: t('member.statuses.all'),
        value: 0
      },
      {
        label: t('member.maintainer_unset'),
        value: 1
      },
      {
        label: t('member.freeuser_unset'),
        value: 2
      }
      ,
      {
        label: t('member.forbidden'),
        value: 3
      }
      ,
      {
        label: t('member.battery_status'),
        value: 4
      }
      ,
      {
        label: t('member.is_vip'),
        value: 5
      }
      ,
      {
        label: t('g.stage'),
        value: 6
      }
    ]
    const orderStatuses = [{
      label: t('member.order_status.all'),
      value: -1
    }, {
      label: t('member.order_status.rented'),
      value: 0
    }, {
      label: t('member.order_status.overdue'),
      value: 1
    }, {
      label: t('member.order_status.new_rent'),
      value: 2
    }, {
      label: t('member.order_status.renew_lease'),
      value: 3
    }, {
      label: t('member.order_status.rent_out'),
      value: 4
    },
    ]
    const render = {
      shortcuts,
      statuses,
      operators,
      userStatuses,
      orderStatuses,
      cascade_options_direct
    }
    const isEnVersion = reactive({
      status: false,
    })
    const formData = reactive({
      tableData,
      curData: {},
      curDataImport: {},
      compare: {
        name: true,
        phone: true,
        IdNum: true,
      },
      importDataList: [],
      selectId: '',
      importIds: [],
      devIdList: [],
      valOperator: 0,
      valStatus: 2,
      valTime: '',
      valTel: '',
      valPhoneNum: '',
      valOrderStatus: '',
      importPhoneNum: '',
      valIdName: '',
      valUserStatus: 0,
      dialogVisible: false,
      dialogImageVisible: false,
      dialogDisplayVisible: false,
      unbindDialogVisible: false,
      mobileDialogVisible: false,
      dialogImportVisible: false,

      adBindInfo: {},
      BadBatteryNum: '',
      GoodBatteryNum: '',
      idCardFrontImgUrl: '',
      idCardBackImgUrl: '',
      // pagination 
      curPage: 1,
      pageSize: 10,
      total: 1,
      checkIdCardImage: false,
      props:{
          children:"Children",
          label:"Name",
          value:"CodePath",
          emitPath:false,
          checkStrictly:true
      },
      IsStartWith:1
    })
    const func = {
      importData() {
        formData.curData = {}
        formData.importPhoneNum = ''
        formData.selectId = ''
        formData.importDataList = []
        formData.curDataImport = {}
        formData.dialogImportVisible = true;
      },
      async returnBattery(row) {
        ElMessageBox.confirm("确保电池已经取回，将退还押金，操作将有延时，确认要退租？", {
          confirmButtonText: t('confirm'),
          cancelButtonText: t('cancel'),
        }).then(async () => {
          let res = await axios.post(`/admin/user/returnbatformoney`, {
            'UserId': row.Id,
          })
          if (res == 'OK') {
            func.loadData()
            return ElMessage({
              type: 'success',
              message: t('g.success')
            })
          }

        })

      },
      async checkIdCardImage(row) {
        try {
          if (formData.checkIdCardImage) {
            return
          }
          formData.checkIdCardImage = true
          let res = await axios.post("/admin/user/getidimg", {
            userid: row.Id,
            imgType: 0,
          })
          // return;
          if (res) {
            formData.idCardFrontImgUrl = 'data:image/bmp;base64,' + res.fImg;
            formData.idCardBackImgUrl = 'data:image/bmp;base64,' + res.bImg;
            formData.dialogImageVisible = true
          }
          formData.checkIdCardImage = false

        } catch { }
      },
      async getbattery(userId) {
        let res = await axios.post(`/admin/user/getbatterylist`, {
          'userId': userId,
          // 'agentcode':'BJRD',
        })
        if (res.List) {
          let arr = []
          for (let item of res.List) {
            arr.push(item.DevId)
          }
          if (arr) {
            return arr
          }
        }

      },
      async showDetails(row, column) {
        if (column && column.label == t('g.operation')) {
          return;
        }
        formData.curData = row;
        formData.dialogDisplayVisible = true;
      },
      async rentOut(row) {
        ElMessageBox.confirm(t('g.sure') + t('member.clear_order_battery') + '?', t('tips'), {
          confirmButtonText: t('confirm'),
          cancelButtonText: t('cancel'),
        }).then(async () => {
          try {
            let res = await axios.post("/admin/user/returnbatandcommbo", {
              userid: row.Id,
            })
            if (res == 'OK') {
              func.loadData()
              return ElMessage({
                type: 'success',
                message: t('g.success')
              })
            }

          } catch (e) {
            console.log("error", e)
          }
        })

      },
      async toggleTester(row) {
        try {
          let isTest = 1 - row.IsTest
          await axios.post("/admin/user/maintainSet", {
            id: row.Id,
            isTest
          })
          row.IsTest = isTest
        } catch (e) {
          console.log("error", e)
        }
      },
      async toggleVip(row) {
        try {
          let IsVIP = !row.IsVIP
          await axios.post("/admin/user/maintainSet", {
            id: row.Id,
            IsVIP
          })
          row.IsVIP = IsVIP
        } catch (e) {
          console.log("error", e)
        }
      },
      async toggleFree(row) {
        try {
          let isFree = 1 - row.IsFree
          await axios.post("/admin/user/maintainSet", {
            id: row.Id,
            isFree
          })
          row.IsFree = isFree
        } catch (e) {
          console.log("error", e)
        }
      },
      async toggleDisable(row) {
        try {
          let isDisable = !row.IsDisable;
          axios.post("/admin/user/maintainSet", {
            id: row.Id,
            isDisable
          })

          row.IsDisable = isDisable
          // this.$nextTick(()=>{

          //   }
          // )

        } catch (e) {
          console.log("error", e)
        }
      },
      showBindDialog(row) {
        if (!row.UserBatteryIds) {
          return;
        }
        formData.dialogVisible = true
        formData.adBindInfo = {}
        formData.curData = row
        formData.devIdList = []
        let arr = row.UserBatteryIds.split(',')

        nextTick(() => {
          arr.forEach(function (value, index) {
            formData.devIdList[index] = {
              value: value
            }
          })
          formData.adBindInfo.oldDevId = formData.devIdList[0].value
        })
      },
      showClearMobile(row) {
        if (!row.IsSmsVerify) {
          return;
        }
        ElMessageBox.confirm(t('member.confirm_clear_mobile') + row.Mobile + t('member.res_mobile_fail'), t('tips'), {
          confirmButtonText: t('confirm'),
          cancelButtonText: t('cancel'),
        }).then(() => {
          try {
            axios.post("/admin/user/smsVerifyClear", {
              userid: row.Id,
            }).then((res) => {
              if (res == 'OK') {
                func.loadData()
                return ElMessage({
                  type: 'success',
                  message: t('member.clear_mobile_success')
                })
              } else {
                return ElMessage({
                  type: 'error',
                  message: t('member.clear_mobile_fail')
                })
              }

            }).catch(() => {
              return ElMessage({
                type: 'error',
                message: t('member.clear_mobile_fail')
              })
            })
          } catch (e) {
            console.log("error", e)
          }

        })

      },
      showunBindDialog(row) {
        if (!row.UserBatteryIds) {
          return;
        }
        formData.unbindDialogVisible = true
        formData.adBindInfo = {}
        formData.curData = row
        formData.devIdList = []
        let arr = row.UserBatteryIds.split(',')
        nextTick(() => {
          arr.forEach(function (value, index) {
            formData.devIdList[index] = {
              value: value
            }
          })
          formData.adBindInfo.oldDevId = formData.devIdList[0].value
        })
      },
      search() {
        func.loadData(1)
      },
      pageChange(val) {
        func.loadData(val)
      },
      refresh() {
        formData.valOperator = 0
        formData.valStatus = 2
        formData.valUserStatus = 0
        formData.valOrderStatus = ''
        formData.valTime = ''
        formData.valTel = ''
        formData.valPhoneNum = ''
        formData.valIdName = ''
        func.loadData(1)
      },
      async bindBattery(type) {
        if (!formData.adBindInfo.newDevId && !formData.adBindInfo.oldDevId) {
          return ElMessage({
            type: 'error',
            message: t('member.input_battery_no')
          })
        }
        try {
          let res = await axios.post("/admin/user/batteryBind", {
            id: formData.curData.Id,
            BadBatteryNum: formData.adBindInfo.oldDevId,
            GoodBatteryNum: formData.adBindInfo.newDevId,
            BindType: type,//ExBind换绑 Unbind 解绑
            IsClearCommbo: formData.adBindInfo.IsClearCommbo || false,
            IsForceExBind: formData.adBindInfo.IsForceExBind || false,
          })
          console.log(JSON.stringify(res), 123);
          formData.dialogVisible = false
          formData.unbindDialogVisible = false
          func.loadData()
          if (res) {
            if (type == 'ExBind') {
              return ElMessage({
                type: 'success',
                message: t('member.bind_success')
              })
            } else if (type == 'Unbind') {
              return ElMessage({
                type: 'success',
                message: t('member.unbind_success')
              })
            }
          }

        } catch (e) {
          console.log("error", e)
        }



      },
      changePhoneId() {
        formData.curData = formData.importDataList.find((item) => { return item.Id == formData.selectId })
        formData.curData.CreatedAtStr = toDay(formData.curData.CreatedAt)
        if (formData.curData.UserComboInfo && formData.curData.UserComboInfo.AllEndAt) {
          formData.curData.AllEndAt = formData.curData.UserComboInfo.AllEndAt ? toDay(formData.curData.UserComboInfo.AllEndAt) : '';
        } else {
          formData.curData.AllEndAt = '无'
        }

      },
      async searchFromPhone() {
        let reg = /^1[23456789]\d{9}$/;
        let phone = formData.importPhoneNum.trim();
        let is_phone = reg.test(phone)
        if (!is_phone) {
          return ElMessage({
            type: 'error',
            message: '输入正确手机号码'
          })
        }
        let params = {
          page: 1
        }
        if (phone) {
          params.phoneNum = phone
        }
        formData.curData = {}
        formData.importIds = []
        formData.curDataImport = {}
        formData.importDataList = []
        const loadingInstance1 = ElLoading.service({ fullscreen: true, text: '加载中' })
        try {
          let res = await axios.get(`/admin/user`, {
            params
          })
          // if(res.List.length>1){
          //   return ElMessage({
          //     type: 'error',
          //     message: '重复号码'
          //   })
          // }

          let a = []
          let b = []
          if (res.List.length > 0) {
            a = res.List[0]
            for (let item of res.List) {
              formData.importIds.push(item.Id)
            }
            formData.selectId = res.List[0].Id
            b = res.List
            a.CreatedAtStr = toDay(a.CreatedAt)
            if (a.UserComboInfo && a.UserComboInfo.AllEndAt) {
              a.AllEndAt = a.UserComboInfo.AllEndAt ? toDay(a.UserComboInfo.AllEndAt) : '';
            } else {
              a.AllEndAt = '无'
            }
          } else {
            loadingInstance1.close()
            return ElMessage({
              type: 'error',
              message: '平台搜索不到号码'
            })
          }
          let res2 = await axios.post(`/admin/usermsg/find`, {
            PhoneNum: phone
          })
          if (res2) {
            formData.curDataImport = res2
            formData.compare.name = a.IdName == formData.curDataImport.Name ? true : false
            formData.compare.phone = a.Mobile == formData.curDataImport.PhoneNum ? true : false
            formData.compare.IdNum = a.IdNum == formData.curDataImport.IdNum ? true : false

            if (formData.curDataImport.EndAt) {
              // formData.curDataImport.EndAtStr = formData.curDataImport.EndAt ? toDay(formData.curDataImport.EndAt) : '';
              formData.curDataImport.EndAtStr = formData.curDataImport.EndAt
            }
          }
          loadingInstance1.close()
          formData.importDataList = b
          formData.curData = a
        } catch {
          loadingInstance1.close()
        }
      },
      colChange({ column, prop, order }) {
        if (order == 'ascending') {
          formData.endCommOrder = 'asc'
          func.loadData(1)
        } else if (order == 'descending') {
          formData.endCommOrder = 'desc'
          func.loadData(1)
        } else {
          formData.endCommOrder = ''
          func.loadData(1)
        }
      },
      async updateImport(id) {
        if (!id) {
          return ElMessage({
            type: 'error',
            message: '数据为空'
          })
        }
        // if(!formData.compare.name||!formData.compare.phone||!formData.compare.IdNum){
        //   return ElMessage({
        //     type: 'error',
        //     message: '信息匹配错误'
        //   })  
        // }
        let t = ''
        if ((formData.curDataImport.EndAtStr + "").length > 17) {
          t = Math.floor(Date.parse(formData.curDataImport.EndAtStr) / 1000)
        } else {
          t = Math.floor(Date.parse(formData.curDataImport.EndAtStr + " 23:59:59") / 1000)
        }
        let res = await axios.post(`/admin/usermsg/import`, {
          UserId: id,
          Money: formData.curDataImport.Money || 0,
          EndAt: t,
        })
        // console.log(JSON.stringify(res));
        if (res) {
          formData.dialogImportVisible = false;
          return ElMessage({
            type: 'success',
            message: res
          })
        }

      },
      async loadData(page = 1) {
        try {
          formData.tableData = []
          formData.curPage = page
          let params = {
            page,
          }
          if (formData.valOperator) {
            params.agentcode = formData.valOperator
          }
          if (formData.valTel) {
            params.nickname = formData.valTel
          }
          if (formData.valIdName) {
            params.idName = formData.valIdName
          }
          if (formData.valPhoneNum) {
            params.phoneNum = formData.valPhoneNum
          }
          if (formData.valStatus != 2) {
            params.status = formData.valStatus
          }
          params.comboStatus = formData.valOrderStatus
          if (userType) {
            params.userType = userType
          }
          if (formData.endCommOrder) {
            params.endCommOrder = formData.endCommOrder
          }
          if (formData.valTime) {
            params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
            params.endAt = Math.floor(Date.parse(formData.valTime[1] + " 23:59:59") / 1000)

          }
          if (formData.valBattaryId) {
            params.batId = formData.valBattaryId
          }
          if (formData.CommTime) {
            params.endCommStartAt = Math.floor(Date.parse(formData.CommTime[0]) / 1000)
            params.endCommEndAt = Math.floor(Date.parse(formData.CommTime[1] + " 23:59:59") / 1000)

          }
          if (formData.valUserStatus) {
            params.userStatus = formData.valUserStatus
          }
          params.IsStartWith = formData.IsStartWith
          let res = await axios.get(`/admin/user`, {
            params
          })
          formData.total = res.Total
          res.List.map((item) => {
            item.CreatedAtStr = toDay(item.CreatedAt)
            item.StatusStr = item.DeviceStatus === 1 ? t('member.statuses.success') : t('member.statuses.failed');
            item.CurMonthCost = item.CurMonthCost.toFixed(2);
            if (item.UserComboInfo && item.UserComboInfo.EndAt) {
              item.EndAtStr = item.UserComboInfo.EndAt ? toDay(item.UserComboInfo.EndAt) : '';
            }
            if (item.UserComboInfo && item.UserComboInfo.AllEndAt) {
              item.AllEndAt = item.UserComboInfo.AllEndAt ? toDay(item.UserComboInfo.AllEndAt) : '';
            }

            if (item.UserComboInfo && item.UserComboInfo.TimesEndAt) {
              item.TimesEndAtStr = item.UserComboInfo.TimesEndAt ? toDay(item.UserComboInfo.TimesEndAt) : '';
            }
            console.log(JSON.stringify(item.TimesEndAtStr), 123);

          })
          formData.tableData = res.List
          formData.pageSize = res.PageSize
        } catch (e) {
          console.log("loadData", e)
        }
      }
    }
    onMounted(() => {
      if (storage.get('locale') == 'En') {
        isEnVersion.status = true;
      }
      console.log("onMounted")
     // updateOperators()
      func.loadData()
    })
    return {
      func,
      render,
      formData,
      isOperator,
      isEnVersion
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;

  .bound-info {
    font-weight: 700;

    >.item {
      margin-bottom: 1rem;
    }
  }

  .avatar {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
  }

  .inp-block {
    display: block;
  }

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;

      >.title {
        font-size: 1rem;
        font-weight: 500;
      }

      >.header-icons {
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;

        .header-icon {
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color: #969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }

    >.btn-ct {
      text-align: right;

      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }

    .inp-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: left;

      >.item {
        width: 31%;
        display: flex;
        align-items: center;
        // justify-content: left;
        margin-bottom: 1.25rem;
        margin-left: 1rem;

        .label {
          display: flex;
          align-items: right;
          justify-content: left;
          // margin-right: 0.325rem;
          // margin-left: 0.3rem;
          width: 5.5rem;
        }

        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }

        .inp {
          flex: 1;
        }
      }
    }
  }

  >.el-alert-my {
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;

    .el-alert-my-txt {
      font-size: 0.875rem;

      >.tips {
        font-weight: bold;
      }
    }

    :deep(.el-alert__closebtn) {
      top: 1.25rem;
      font-size: 1rem;
    }
  }

  >.data-table {
    :deep(.el-table) {
      // .cell {
      //   // white-space: nowrap;
      //   // text-overflow: ellipsis;
      // }

      .el-table__row {
        cursor: pointer;
      }
    }
  }

  >.pagination {
    text-align: right;
    margin-top: 1.25rem;
  }

  .el-form-row {
    display: flex;
    justify-content: space-between;

    :deep(.el-form-item) {
      width: 49%;
    }
  }

  .el-select-my {
    text-align: left;
    display: block;
  }

  :deep(.m-dialog) {
    max-width: 70rem;
  }

  .import-dialog {
    margin-top: 50px;
    // width:60%;
    max-width: 70rem;
    min-width: 50rem;

    .search {
      display: flex;
      justify-content: center;
    }

    .dlg-content {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      >div {
        flex: 1;
      }

    }
  }

}

@media screen and (max-width:800px) {
  .content {
    >.card-box {
      .inp-list {
        >.item {
          width: 100%;
        }
      }
    }
  }
}
</style>
