<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">{{ $t("g.condition") }}</span>
      </div>
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t("g.create_at") }}</span>
          <el-date-picker class="inp" v-model="formData.valTime" value-format="YYYY-MM-DD" size="small" type="daterange"
            unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
            :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
          </el-date-picker>
        </div>

        <!-- <div class="item" v-if="!isOperator()">
          <span class="label">{{ $t('g.operator') }}</span>
          <el-select class="inp" size="small" v-model="formData.valOperator" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.operators" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div> -->
        <div class="item">
          <span class="label">{{ $t('g.operator') }}</span>
          <el-cascader  size="small"  style="width: 100%;flex: 1;" :clearable="true" v-model="formData.valOperator" :props="formData.props" :options="render.cascade_options_direct" />
        </div>
        <div class="item">
          <span class="label">关联查下级</span>
          <el-select class="inp" size="small" v-model="formData.IsStartWith" :placeholder="$t('g.choose')">
            <el-option label="否" :value="0">
            </el-option>
            <el-option label="是" :value="1">
            </el-option>
          </el-select>
        </div>
        
        <div class="item">
          <span class="label">{{ $t("member.mobile") }}</span>
          <el-input class="inp" size="small" v-model="formData.valPhoneNum"
            :placeholder="$t('member.mobile')"></el-input>
        </div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.search" class="search" type="primary" size="small">{{ $t('g.search') }}</el-button>
      </div>
    </div>
    <div class="card-box data-table">
      <div class="header">
        <span class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize
          }}</span>
        <div class="header-icons">
          <el-icon @click="func.refresh()" class="header-icon">
            <Refresh />
          </el-icon>
        </div>
      </div>
      <el-table :data="formData.tableData" height="420" style="width: 100%" @row-click.self="func.showDetails">
        <el-table-column prop="Id" label="Id" width="80" fixed>
        </el-table-column>
        <el-table-column prop="UserId" width="100" :label="$t('openSlotsLit.user_id')">
        </el-table-column>
        <el-table-column prop="Nickname" :label="$t('bill.nickname')" width="100">
        </el-table-column>
        <el-table-column prop="AgentCode"  v-if="!isOperator()" width="100" :label="$t('g.agent_code')" >
        </el-table-column>
        <el-table-column prop="NamePath" min-width="180" label="运营商归属" :show-overflow-tooltip='true'>
        </el-table-column>
        <el-table-column prop="Mobile" width="120" :label="$t('member.mobile')"></el-table-column>
        <el-table-column prop="IdNum" width="200" :label="$t('member.IdNum')">
        </el-table-column>
        <el-table-column prop="Money" width="100" :label="$t('member.balance')">
        </el-table-column>
        <el-table-column prop="EndAt" width="260" :label="$t('member.expire')">
        </el-table-column>
        <el-table-column prop="ResStr" width="100" :label="$t('member.status')">
        </el-table-column>
        <el-table-column prop="CreatedAtStr" width="260" :label="$t('g.create_at')">
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
        :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
      </el-pagination>
    </div>
    <el-dialog :title="$t('g.detail')" v-model="formData.dialogDisplayVisible"  width="98%">
      <el-descriptions border>
        <el-descriptions-item :label="$t('openSlotsLit.user_id')">{{ formData.curData.UserId }}</el-descriptions-item>
        <el-descriptions-item :label="$t('bill.nickname')">{{ formData.curData.Nickname }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.mobile')">{{ formData.curData.Mobile }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.IdNum')">{{ formData.curData.IdNum }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.agent_code')" v-if="!isOperator()">{{ formData.curData.AgentCode
          }}</el-descriptions-item>
            <el-descriptions-item :label="$t('member.balance')">{{ formData.curData.Money }}</el-descriptions-item>
            <el-descriptions-item :label="$t('member.expire')">{{ formData.curData.EndAt }}</el-descriptions-item>
            <el-descriptions-item :label="$t('member.status')">{{ formData.curData.ResStr }}</el-descriptions-item>
            <el-descriptions-item :label="$t('g.create_at')">{{ formData.curData.CreatedAtStr }}</el-descriptions-item>
       </el-descriptions>
        
      <template #footer>
        <span class="dialog-footer">
          <el-button type="default" @click="formData.dialogDisplayVisible = false">{{ $t('g.btn_cancel') }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { reactive, onMounted, VueElement, nextTick } from 'vue'
import axios from 'axios'
import { operators, updateOperators, shortcuts ,cascade_options_direct} from '@/data/default'
import { toDay } from "@/common/func"
import { ElMessage, ElMessageBox, ElLoading } from 'element-plus'
import i18n from '@/data/i18n/index'
import { useRoute } from 'vue-router'
import { isOperator } from '@/data/token'
import storage from '@/common/storage'
export default {
  name: 'importRecord',
  setup() {
    const t = i18n.global.t
    const tableData = []
    const formData = reactive({
      tableData,
      curData: {},
      curPage: 1,
      pageSize: 10,
      total: 1,
      valTime:'',
      valOperator:'',
      valIdName:'',
      valPhoneNum:'',
      dialogDisplayVisible:false,
      props:{
          children:"Children",
          label:"Name",
          value:"CodePath",
          emitPath:false,
          checkStrictly:true,
      },
      IsStartWith:1
    })
    const func = {
      pageChange(val) {
        func.loadData(val)
      },
      showDetails(row,column){
        if(column&&column.label==t('g.operation')){
          return;
        }
        formData.dialogDisplayVisible = true;
        formData.curData = row;
      },
      search() {
        // console.log('formData', formData)
        func.loadData(1)
      },
      refresh(){
        formData.valOperator = 0
        formData.valTime = ''
        formData.valTel = ''
        formData.valPhoneNum = ''
        formData.valIdName = ''
        func.loadData(1)
      },
      async loadData(page=1){
        try{
          formData.tableData = []
          formData.curPage = page
          let params = {
            page,
          }
          if (formData.valOperator) {
            params.agentCode = formData.valOperator
          }
          if (formData.valPhoneNum) {
            params.phoneNum = (formData.valPhoneNum+'').trim()
          }
          if (formData.valIdName) {
            params.idName = formData.valIdName
          }
          if (formData.valTime) {
            params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
            params.endAt = Math.floor(Date.parse(formData.valTime[1] + " 23:59:59") / 1000)
          }
          params.IsStartWith = formData.IsStartWith
          let res = await axios.get(`/admin/usermsg/get`, {
            params
          })
          formData.total = res.Total
          res.List.map((item) => {
            item.CreatedAtStr = item.CreateAt?toDay(item.CreateAt):''
          })
          // console.log(JSON.stringify(res.List));
          
          formData.tableData = res.List
          formData.pageSize = res.PageSize
        }catch{
          console.log("loadData", e)
        }
      },
    }
    const render = {
      shortcuts,
      operators,
      cascade_options_direct
    }
    onMounted(() => {
      func.loadData()
    })
    return {
      func,
      formData,
      isOperator,
      render
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;
  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;
    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;
      >.title {
        font-size: 1rem;
        font-weight: 500;
      }
      >.header-icons {
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;
        .header-icon {
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color: #969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }
    >.btn-ct {
      text-align: right;
      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }
    .inp-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: left;
      >.item {
        width: 31%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;
        margin-left: 1rem;
        .label {
          display: flex;
          align-items: right;
          justify-content: left;
          width: 5rem;
        }
        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }
        .inp {
          flex: 1;
        }
      }
    }
  }
  :deep(.m-dialog) {
    max-width: 70rem;
  }
}
</style>
