<template>
  <div class="content">
    <!-- v-if="!isOperator()" -->
    <div class="card-box">
      <div class="header">
        <span class="title">{{ $t("g.condition") }}</span>
      </div>
      <div class="inp-list">
        <!-- <div class="item">
          <span class="label">{{ $t("g.operator") }}</span>
          <el-select class="inp" size="small" v-model="formData.Agentcode" :placeholder="$t('g.choose')"
            @change="func.OperatorChange">
            <el-option v-for="item in render.operatorList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div> -->
        <div class="item" >
          <span class="label">{{ $t('g.operator') }}</span>
          <el-cascader size="small"  @change="func.OperatorChange" style="width: 100%;" :clearable="true" v-model="formData.Agentcode" :props="formData.props" :options="render.cascade_options_direct" />
        </div>
        <!-- <div class="item">
          <span class="label">关联查下级</span>
          <el-select class="inp" size="small" v-model="formData.IsStartWith" :placeholder="$t('g.choose')">
            <el-option label="否" :value="0">
            </el-option>
            <el-option label="是" :value="1">
            </el-option>
          </el-select>
        </div> -->
      </div>
    </div>
    <div class="databox_title">收益统计</div>
    <div class="databox">
      <div class="databox_item">
        <div>{{ formData.orderData.TotalMoney }}</div>
        <div>总收益</div>
      </div>
      <div class="databox_item">
        <div>{{ formData.orderData.YesterdayMoney }}</div>
        <div>昨天收益</div>
      </div>
      <div class="databox_item">
        <div>{{ formData.orderData.YesterdayOrderCount }}</div>
        <div>昨天收益订单数</div>
      </div>
      <div class="databox_item">
        <div>{{ formData.orderData.TodayMoney }}</div>
        <div>今天收益</div>
      </div>
      <div class="databox_item">
        <div>{{ formData.orderData.TodayOrderCount }}</div>
        <div>今天收益订单数</div>
      </div>
      <div class="databox_item">
        <div>{{ formData.orderData.YesterdayExorderCount }}</div>
        <div>昨天换电订单数</div>
      </div>
      <div class="databox_item">
        <div>{{ formData.orderData.TodayExorderCount }}</div>
        <div>今天换电订单数</div>
      </div>
    </div>
    <div class="databox_time">
      <el-date-picker class="inp" v-model="formData.valTime3" value-format="YYYY-MM-DD" type="daterange" unlink-panels
        :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')" :end-placeholder="$t('g.end_date')"
        :shortcuts="render.shortcuts">
      </el-date-picker>
      <el-button @click="func.get_ordergain" class="search" type="primary" size="small">{{ $t("g.search")
        }}</el-button>
    </div>
    <div class="databox">
      <div class="databox_item">
        <div>{{ formData.orderData.ResMoney }}</div>
        <div>收益</div>
      </div>
      <div class="databox_item">
        <div>{{ formData.orderData.ResOrderCount }}</div>
        <div>订单数</div>
      </div>
    </div>
    <div class="databox_title">电池统计</div>
    <div class="databox">
      <div class="databox_item">
        <div>{{ formData.stat.InIdleCount }}</div>
        <div>空闲</div>
      </div>
      <div class="databox_item">
        <div>{{ formData.stat.InDevCount }}</div>
        <div>在柜数</div>
      </div>
      <div class="databox_item">
        <div>{{ formData.stat.InManCount }}</div>
        <div>在骑手</div>
      </div>
      <div class="databox_item">
        <div>{{ formData.stat.OfflineCount }}</div>
        <div>离线电池</div>
      </div>
      <div class="databox_item">
        <div>{{ formData.stat.OnlineCount }}</div>
        <div>在线电池</div>
      </div>
      <div class="databox_item">
        <div>{{ formData.stat.TatolCount }}</div>
        <div>总数</div>
      </div>
    </div>
    <div class="databox_flex">
      <div class="databox_title">用户统计</div>
      <!-- <div class="databox_con">
        <div
          class="databox_btn"
          :class="{ btn_active: formData.active == 1 }"
          @click="func.opt_active(1)"
        >
          所有
        </div>
        <div
          class="databox_btn"
          :class="{ btn_active: formData.active == -1 }"
          @click="func.opt_active(-1)"
        >
          昨天
        </div>
        <div
          class="databox_btn"
          :class="{ btn_active: formData.active == 0 }"
          @click="func.opt_active(0)"
        >
          今天
        </div>
      </div> -->
    </div>
    <div class="databox">
      <div class="databox_item">
        <div>{{ formData.combostat.Rent }}</div>
        <div>在租(有效套餐)</div>
      </div>
      <div class="databox_item">
        <div>{{ formData.combostat.OverTime }}</div>
        <div>逾期</div>
      </div>
      <div class="databox_item">
        <div>{{ formData.combostat.AbandonRent }}</div>
        <div>退租</div>
      </div>
    </div>
    <div class="databox_time">
      <el-date-picker class="inp" v-model="formData.valTime2" value-format="YYYY-MM-DD" type="daterange" unlink-panels
        :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')" :end-placeholder="$t('g.end_date')"
        :shortcuts="render.shortcuts">
      </el-date-picker>
      <el-button @click="func.getcombostat" class="search" type="primary" size="small">{{ $t("g.search")
        }}</el-button>
    </div>
    <div class="databox">
      <div class="databox_item">
        <div>{{ formData.combostat.NewRent }}</div>
        <div>新租</div>
      </div>
      <div class="databox_item">
        <div>{{ formData.combostat.ReNewRent }}</div>
        <div>续租</div>
      </div>
      <div class="databox_item" style="color:red">
        <div>{{ formData.combostat.ExchangeUsersCount }}</div>
        <div>换电用户数量</div>
      </div>
    </div>
    <div class="databox_flex2">
      <div class="databox_title">订单统计</div>
      <div class="opt_flex">
        <!-- <span class="label">{{ $t("g.create_at") }}：</span> -->
        统计类型：<el-select v-model="formData.typeValue" class="mg_right">
          <el-option :key="1" label="机柜订单统计" :value="1" />
          <el-option :key="2" label="换电订单统计" :value="2" />
        </el-select>
        <el-date-picker class="inp" v-model="formData.valTime" value-format="YYYY-MM-DD" type="daterange" unlink-panels
          :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')" :end-placeholder="$t('g.end_date')"
          :shortcuts="render.shortcuts">
        </el-date-picker>
        <el-button @click="func.search" class="search" type="primary" size="small">{{ $t("g.search") }}</el-button>
      </div>
    </div>
    <div class="card-box data-table">
      <div class="header">
        <span class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{
          $t("g.per_page")
        }}:{{ formData.pageSize }}</span>
        <span v-show="formData.type == true" class="title2"> <b style="margin-right: 6px;">(换电数量)</b>
          成功：{{ formData.AllSuccOrder }}，失败：{{ formData.AllFailOrder }}，总计：{{ formData.AllSuccOrder +
            formData.AllFailOrder }}</span>
        <div class="header-icons">
          <!-- <i @click="func.showAddDataDialog()" class="el-icon-plus header-icon"></i> -->
        </div>
      </div>
      <el-table @row-click="func.showDetails" :data="formData.tableData" style="width: 100%" show-summary
        v-show="formData.type == true">
        <el-table-column prop="SiteName" label="站点名称" width="260">
        </el-table-column>
        <el-table-column prop="DeviceId" label="设备ID" width="380">
        </el-table-column>
        <el-table-column prop="SuccCount" label="成功">
        </el-table-column>
        <el-table-column prop="FailCount" label="失败">
          <template #default="scope">
            <el-tag type="danger">{{ scope.row.FailCount }}</el-tag>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="AllCount" label="总计" >
        </el-table-column> -->
      </el-table>
      <el-table :data="formData.tableData" style="width: 100%" v-show="formData.type == false">
        <el-table-column prop="UserId" label="用户ID" width="260">
        </el-table-column>
        <el-table-column prop="UserName" label="用户昵称" width="380">
        </el-table-column>
        <el-table-column prop="UserMobile" label="手机号">
        </el-table-column>
        <el-table-column prop="Count" label="换电次数">
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
        :page-size="formData.pageSize" :current-page="formData.fail_page" :total="formData.total">
      </el-pagination>
    </div>
    <el-dialog title="失败订单详情" v-model="formData.dialogDisplayVisible" width="80%" top="10vh" @close="func.dialog_close"
      :infinite-scroll-immediate="false" infinite-scroll-delay="500">
      <div class="fail_title">
        {{ $t("g.unit_num") }}:{{ formData.fail_total }}，{{
          $t("g.per_page")
        }}:{{ formData.pageFailSize }}
      </div>
      <el-table :data="formData.fail_list" style="width: 100%">
        <el-table-column prop="Nickname" label="昵称" width="160">
        </el-table-column>
        <el-table-column prop="Mobile" label="号码" width="120">
        </el-table-column>
        <el-table-column prop="DeviceId" label="设备编号" width="260">
        </el-table-column>
        <el-table-column prop="DeviceSiteName" label="站点名称" width="180">
        </el-table-column>
        <el-table-column prop="FailReason" label="失败原因"> </el-table-column>
        <el-table-column prop="CreatedAt" :label="$t('g.create_at')" width="170">
        </el-table-column>
      </el-table>
      <div class="pagination pagination_top">
        <el-pagination background layout="prev, pager, next" @current-change="func.pageChangeFail"
          :page-size="formData.pageFailSize" :current-page="formData.curFailPage" :total="formData.fail_total">
        </el-pagination>
      </div>
    </el-dialog>
    <el-dialog :title="$t('g.detail')" v-model="formData.dialogVisible" width="60%">
      <el-form label-width="80px" :model="formData.curData">
        <div class="el-form-row">
          <el-form-item :label="$t('member.status')">
            <el-select class="inp" size="small" v-model="formData.curData.IsValid" :placeholder="$t('g.choose')">
              <el-option v-for="item in render.updateStatuses" :key="item.value" :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('member.mchid2')">
            <el-input size="small" type="number" v-model="formData.curData.Mchid"></el-input>
          </el-form-item>
        </div>
        <div class="el-form-row">
          <el-form-item :label="$t('member.mchid_zfb')">
            <el-input size="small" type="number" v-model="formData.curData.AliAppid"></el-input>
          </el-form-item>
          <el-form-item :label="$t('member.password')">
            <el-input size="small" type="password" v-model="formData.curData.password"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="func.updateData()">{{
            $t("g.btn_confirm")
          }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, onMounted, computed } from "vue";
import { shortcuts, updateOperators, operatorList ,cascade_options_direct} from "@/data/default";
import { toDay } from "@/common/func";
import axios from "axios";
import { ElMessage, dayjs } from "element-plus";
import i18n from "@/data/i18n/index";
import { isOperator, isAdmin } from "@/data/token";

export default {
  name: "ExpensesAgents",
  setup() {
    const t = i18n.global.t;
    const tableData = [];
    const statuses = [
      {
        label: t("expenses.statuses.all"),
        value: 0,
      },
      {
        label: t("expenses.statuses.enabled"),
        value: 1,
      },
      {
        label: t("expenses.statuses.disabled"),
        value: 2,
      },
    ];

    const render = {
      statuses,
      updateStatuses: [
        {
          label: t("expenses.enable"),
          value: 1,
        },
        {
          label: t("expenses.disable"),
          value: 0,
        },
      ],
      shortcuts,
      operatorList,
      cascade_options_direct
    };
    const formData = reactive({
      tableData,
      typeValue: 1,
      curData: {},
      valTime: [
        dayjs.unix(new Date() / 1000).format("YYYY-MM-DD"),
        dayjs.unix(new Date() / 1000).format("YYYY-MM-DD"),
      ],
      valTime2: [
        dayjs.unix(new Date() / 1000).format("YYYY-MM-DD"),
        dayjs.unix(new Date() / 1000).format("YYYY-MM-DD"),
      ],
      valTime3: [
        dayjs.unix(new Date() / 1000).format("YYYY-MM-DD"),
        dayjs.unix(new Date() / 1000).format("YYYY-MM-DD"),
      ],
      valStatus: 0,
      dialogVisible: false,
      dialogDisplayVisible: false,
      curPage: 1,
      curFailPage: 1,
      pageSize: 1,
      pageFailSize: 1,
      total: 10,
      fail_total: 10,
      valName: "",
      valCode: "",
      active: -1,
      combostat: {},
      orderData: {},
      stat: {},
      DeviceId: "",
      fail_list: [],
      Agentcode: '',
      type: true,
      AllSuccOrder: 0,
      AllFailOrder: 0,
      props:{
          children:"Children",
          label:"Name",
          value:"CodePath",
          emitPath:false,
          checkStrictly:true
      },
      IsStartWith:1
    });
    const func = {
      async showDetails(row, column) {
        formData.DeviceId = row.DeviceId;
        func.get_orderfail();
        // if(column&&column.label==t('g.operation')){
        //   return;
        // }
        // formData.tableData = []
        //   formData.curPage = page
        //   let params = {
        //       page,
        //   }
        //   if(formData.valTime){
        //     params.StartTime = Math.floor(Date.parse(formData.valTime[0]) / 1000)
        //     params.Endtime = Math.floor(Date.parse(formData.valTime[1]+" 23:59:59") / 1000)
        //   }
        //   let res = await axios.get(`/admin/device/orderfail`,{
        //     params
        //   })
        //   console.log(res,params)
        formData.dialogDisplayVisible = true;
        // formData.curData = row;
      },
      dialog_close() {
        formData.fail_total = 10;
        formData.pageFailSize = 1;
      },
      search() {
        if (formData.typeValue == 1) {
          formData.type = true
          func.loadData()
        } else {
          func.loadData2()
          formData.type = false
        }
      },
      rowDblClick(row, column, e) {
        console.log("rowDblClick", row, column, e);
        formData.curData = row;
        formData.dialogVisible = true;
      },
      OperatorChange() {
        formData.total = 10;
        formData.pageSize = 1;
        formData.typeValue = 1
        formData.type = true
        func.loadData();
        func.getcombostat();
        func.get_ordergain();
        func.get_stat();
      },
      pageChange(val) {
        console.log("page change", val);
        if (formData.typeValue == 1) {
          func.loadData(val);
        } else {
          func.loadData2(val);
        }
      },
      pageChangeFail(val) {
        console.log("page change", val);
        func.get_orderfail(val);
      },
      showAddDataDialog() {
        formData.curData = {};
        formData.dialogVisible = true;
      },
      async get_orderfail(page = 1) {
        formData.curFailPage = page;
        let params = {
          page,
          DeviceId: formData.DeviceId,
        };
        // if (!isOperator()) {
        //   params.Agentcode = formData.Agentcode
        // }
        params.Agentcode = formData.Agentcode
        if (formData.valTime) {
          params.StartTime = Math.floor(
            Date.parse(formData.valTime[0] + " 00:00:00") / 1000
          );
          params.EndTime = Math.floor(
            Date.parse(formData.valTime[1] + " 23:59:59") / 1000
          );
        }
        let res = await axios.get(`/admin/device/orderfail`, {
          params,
        });

        res.List.map((item) => {
          item.CreatedAt = toDay(item.CreatedAt);
        });
        formData.fail_total = res.Total;
        formData.fail_list = res.List;
        formData.pageFailSize = res.PageSize;
      },
      async updateData() {
        if (typeof formData.curData.Status == "undefined") {
          ElMessage({
            type: "error",
            message: t("device.please_choose_status"),
          });
          return;
        }
        try {
          const params = {
            id: formData.curData.Id,
            mchid: formData.curData.Mchid,
            isValid: formData.curData.IsValid,
          };
          if (formData.curData.password) {
            params.password = formData.curData.password;
          }
          await axios.put("/admin/agent", params);
        } catch (e) { }
        formData.dialogVisible = false;
        func.loadData(formData.curPage);
        //更新reactive数据
        updateOperators();
      },
      async opt_active(num) {
        formData.active = num;
        func.getcombostat();
      },
      async search2() {

      },
      async get_stat() {
        let params = {};
        // if (!isOperator()) {
        //   params.Agentcode = formData.Agentcode
        // }
        params.Agentcode = formData.Agentcode
        let res = await axios.get(`/admin/battery/getstat`, {
          params
        });
        formData.stat = res;
      },
      async get_ordergain() {
        let params = {};
        // if (!isOperator()) {
        //   params.Agentcode = formData.Agentcode
        // }
        params.Agentcode = formData.Agentcode
        if (formData.valTime3) {
          params.StartAt = Math.floor(
            Date.parse(formData.valTime3[0] + " 00:00:00") / 1000
          );
          params.EndAt = Math.floor(
            Date.parse(formData.valTime3[1] + " 23:59:59") / 1000
          );
        }
        let res = await axios.get(`/admin/order/ordergain`, {
          params
        });
        formData.orderData = res;
        console.log(res, '数据')
      },
      async getcombostat() {
        let params = {};
        // if (!isOperator()) {
        //   params.Agentcode = formData.Agentcode
        // }
        params.Agentcode = formData.Agentcode
        if (formData.valTime2) {
          params.StartAt = Math.floor(
            Date.parse(formData.valTime2[0] + " 00:00:00") / 1000
          );
          params.EndAt = Math.floor(
            Date.parse(formData.valTime2[1] + " 23:59:59") / 1000
          );
        }

        let res = await axios.get(`/admin/user/getcombostat`, {
          params,
        });
        formData.combostat = res;
        console.log(res, '数据123')
      },
      async loadData2(page = 1) {
        try {
          formData.tableData = [];
          formData.curPage = page;
          let params = {
            page,
          };
          // if (!isOperator()) {
          //   params.AgentCode = formData.Agentcode
          // }
          params.Agentcode = formData.Agentcode
          if (formData.valTime) {
            params.StartAt = Math.floor(
              Date.parse(formData.valTime[0] + " 00:00:00") / 1000
            );
            params.EndAt = Math.floor(
              Date.parse(formData.valTime[1] + " 23:59:59") / 1000
            );
          }
          let res = await axios.get(`/admin/user/getexboxuser`, {
            params,
          });
          console.log(res, '换电数数据')
          if (!res) {
            formData.total = 0;
            return;
          }
          formData.total = res.Total;
          formData.tableData = res.List;
          formData.pageSize = res.PageSize;
          formData.fail_page = page
          console.log(formData.curFailPage, 'adad')
        } catch (e) {
          console.log("loadData", e);
        }
      },
      async loadData(page = 1) {
        try {
          formData.tableData = [];
          formData.curPage = page;
          let params = {
            page,
          };
          // if (!isOperator()) {
          //   params.Agentcode = formData.Agentcode
          // }
          params.Agentcode = formData.Agentcode
          if (formData.valTime) {
            params.StartTime = Math.floor(
              Date.parse(formData.valTime[0] + " 00:00:00") / 1000
            );
            params.EndTime = Math.floor(
              Date.parse(formData.valTime[1] + " 23:59:59") / 1000
            );
          }
          params.IsStartWith = formData.IsStartWith
          
          let res = await axios.get(`/admin/device/orderstat`, {
            params,
          });
          if (!res) {
            formData.total = 0;
            return;
          }
          console.log(res, '数据1212')
          formData.total = res.Total;
          // res.List.map((item)=>{
          //   item.CreateAtStr = toDay(item.CreateAt)
          // })
          formData.tableData = res.List;
          formData.pageSize = res.PageSize;
          formData.fail_page = page
          formData.AllSuccOrder = res.AllSuccOrder;
          formData.AllFailOrder = res.AllFailOrder;
        } catch (e) {
          console.log("loadData", e);
        }
      },
    };
    onMounted(() => {
      func.loadData();
      func.getcombostat();
      func.get_ordergain();
      func.get_stat();
      //处理请求运营商列表的默认值设置
      // function get_opt (){
      //   return new Promise((resolve,reject)=>{
      //     resolve(operatorList);
      //   });
      // }
      // async function defaultAgent(){
      //   let name = await get_opt()
      //   console.log(name[0].value)
      // }
      // defaultAgent()
    });
    return {
      func,
      render,
      formData,
      isOperator,
      isAdmin
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;

      >.title {
        font-size: 1rem;
        font-weight: 500;
      }

      >.header-icons {
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;

        .header-icon {
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color: #969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }

    >.btn-ct {
      text-align: right;

      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }

    .inp-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;

      >.item {
        width: 49%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;

        .label {
          margin-right: 0.625rem;
          width: 4.375rem;
        }

        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }

        .inp {
          flex: 1;
        }
      }
    }
  }

  >.el-alert-my {
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;

    .el-alert-my-txt {
      font-size: 0.875rem;

      >.tips {
        font-weight: bold;
      }
    }

    :deep(.el-alert__closebtn) {
      top: 1.25rem;
      font-size: 1rem;
    }
  }

  >.data-table {
    :deep(.el-table) {
      .cell {
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .el-table__row {
        cursor: pointer;
      }
    }
  }

  >.pagination {
    text-align: right;
    margin-top: 1.25rem;
  }

  .el-form-row {
    display: flex;
    justify-content: space-between;

    :deep(.el-form-item) {
      width: 49%;
    }

    .inp {
      width: 100%;
    }
  }

  .el-select-my {
    text-align: left;
    display: block;
  }

  :deep(.m-dialog) {
    max-width: 50rem;
  }
}

@media screen and (max-width: 800px) {
  .content {
    >.card-box {
      .inp-list {
        >.item {
          width: 100%;
        }
      }
    }
  }
}

.databox_title {
  font-size: 22px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 15px;
}

.databox {
  display: flex;
  margin-bottom: 15px;
}

.databox_item {
  width: 126px;
  background-color: #fff !important;

  border-radius: 5px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.databox_item:not(:first-child) {
  margin-left: 15px;
}

.databox_con {
  display: flex;
  margin-bottom: 15px;
  margin-left: 362px;
}

.databox_flex {
  display: flex;
  align-items: center;

  //justify-content: space-between;
}

.databox_time {
  text-align: left;
  margin-bottom: 15px;
}

.databox_btn {
  padding: 6px 13px;
  border: solid 1px gray;
  cursor: pointer;
}

.databox_btn:not(:first-child) {
  margin-left: 15px;
}

.btn_active {
  border-color: rgb(64, 158, 255);
  color: rgb(64, 158, 255);
}

.databox_flex2 {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.search {
  margin-left: 20px;
  margin-bottom: 5px;
}

.descriptions_magtop {
  margin-top: 30px;
}

.dialog_box {
  height: 650px;
  overflow-y: auto;
}

.fail_title {
  text-align: left;
  margin-bottom: 1.25rem;
  font-size: 1rem;
  font-weight: 500;
}

.pagination_top {
  margin-top: 35px;
}

.mg_right {
  margin-right: 10px;
}

.opt_flex {
  display: flex;
  align-items: center;
  // padding-top: 60px;
  margin-bottom: 10px;
}

.title2 {
  font-size: 1rem;
  font-weight: 500;
  margin-left: 20px;
}
</style>
