<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">{{$t('decision.stat_condition')}}</span>
      </div>
      <div class="inp-list">
        <!-- <div class="item" v-if="!isOperator()">
          <span class="label">{{$t('g.operator')}}</span>
          <el-select size="small" v-model="formData.valOperator" :placeholder="$t('g.choose')">
              <el-option
                v-for="item in render.operators"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </div> -->
        <div class="item" >
          <span class="label">{{ $t('g.operator') }}</span>
          <el-cascader size="small" :clearable="true" v-model="formData.valOperator" :props="formData.props" :options="render.cascade_options_direct" />
        </div>
        <div class="item">
          <span class="label">{{$t('decision.cycle')}}</span>
          <el-select size="small" v-model="formData.valCircle" :placeholder="$t('g.choose')">
              <el-option
                v-for="item in render.circles"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </div>
        <div class="item">
          <span class="label">{{$t('decision.time')}}</span>
          <el-date-picker
            size="small"
            v-model="formData.valTime"
            :type="dpType"
            :placeholder="$t('g.choose')">
          </el-date-picker>
        </div>
      </div>
    </div>
    <div class="box-2">
      <OrderStatus :agentCode="formData.valOperator" :cycle="formData.valCircle" :startAt="formData.valTime" id="1" class="card-box"/>
      <OrderRatio :agentCode="formData.valOperator" :cycle="formData.valCircle" :startAt="formData.valTime" class="card-box"/>
    </div>
    <OrderToday id="today" :agentCode="formData.valOperator" class="card-box"/>
    <OrderCircle :agentCode="formData.valOperator" :cycle="formData.valCircle" :startAt="formData.valTime" type="ebox" class="card-box"/>
    <OrderCircle :agentCode="formData.valOperator" :cycle="formData.valCircle" :startAt="formData.valTime" type="cbox" class="card-box"/>
    <OrderCircle :agentCode="formData.valOperator" :cycle="formData.valCircle" :startAt="formData.valTime" type="pile" class="card-box"/>
    <OrderMoney :agentCode="formData.valOperator" :cycle="formData.valCircle" :startAt="formData.valTime" class="card-box"/>
  </div>
</template>

<script>
import { reactive,computed } from 'vue'
import OrderStatus from '@/components/charts/OrderStatus.vue'
import OrderRatio from '@/components/charts/OrderRatio.vue'
import OrderToday from '@/components/charts/OrderToday.vue'
import OrderCircle from '@/components/charts/OrderCircle.vue'
import OrderMoney from '@/components/charts/OrderMoney.vue'
import { operators,cascade_options_direct} from '@/data/default'
import i18n from '@/data/i18n/index'
import { isOperator } from '@/data/token'

export default {
  name: 'OperationOrders',
  components: {
    OrderStatus,
    OrderRatio,
    OrderToday,
    OrderCircle,
    OrderMoney,
  },
  setup () {
    const t  = i18n.global.t
    const valOperator = 0
    const valCircle = 1
    //今年1月1号
    const now = new Date()
    const valTime = new Date(`${now.getFullYear()}/01/01 00:00:00`)
    const circles = [
      {
        label: t('decision.cycles.by_year'),
        value: 1
      }, {
        label: t('decision.cycles.by_month'),
        value: 2
      }, {
        label: t('decision.cycles.by_day'),
        value: 3
      }
    ]
    const render = {
      operators,
      circles,
      cascade_options_direct
    }
    const formData = reactive({
      valOperator,
      valCircle,
      valTime,
      props:{
          children:"Children",
          label:"Name",
          value:"Code",
          emitPath:false,
          checkStrictly:true
      },
    })
    const dpType = computed(()=>{
      return formData.valCircle==1?'year':(formData.valCircle==2?'month':'day')
    })
    return {
      formData,
      dpType,
      render,
      isOperator,
    }
  }
}
</script>

<style lang="scss" scoped>
  .content{
    box-sizing: border-box;
    padding: 1.25rem;
    margin-bottom: 6.25rem;
    .card-box{
      font-size: 0.875rem;
      background-color: #fff;
      box-shadow: $box-shadow;
      box-sizing: border-box;
      padding: 1.25rem;
      border-radius: 0.3rem;
      margin-bottom: 1.25rem;
      .header{
        text-align:left;
        margin-bottom: 1.25rem;
        >.title{
          font-size:1rem;
          font-weight: 500;
        }
      }
      .inp-list{
        display: flex;
        align-items: center;
        >.item{
          margin-right: 3rem;
          .label{
            margin-right: 0.625rem;
          }
        }
      }
    }
    >.el-alert-my{
      margin-top: 1.25rem;
      box-shadow: $box-shadow;
      padding: 1.25rem;
      .el-alert-my-txt{
        font-size: 0.875rem;
        >.tips{
          font-weight: bold;
        }
      }
      :deep(.el-alert__closebtn){
        top: 1.25rem;
        font-size: 1rem;
      }
    }
    >.box-2{
      display: flex;
      justify-content: space-between;
      >.card-box{
        width: 49%;
      }
    }
  }
  @media screen and (max-width:800px) {
      .content{
        >.box-2{
          flex-wrap: wrap;
          .card-box{
            width: 100%;
          }
        }
        >.card-box{
          .inp-list{
            flex-wrap: wrap;
            >.item{
              width: 100%;
              margin-right: 0;
              margin-bottom: 1rem;
              display: flex;
              align-items: center;
              .label{
                width: 5rem;
                text-align: right;
              }
            }
          }
        }
      }
  }
</style>
