<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">{{$t("g.condition")}}</span>
      </div>
      <div class="inp-list">
        <div class="item">
          <span class="label">{{$t("g.create_at")}}</span>
          <el-date-picker
                class="inp"
                v-model="formData.valTime"
                size="small"
                type="daterange"
                unlink-panels
                :range-separator="$t('g.to')"
                :start-placeholder="$t('g.start_date')"
                :end-placeholder="$t('g.end_date')"
                :shortcuts="render.shortcuts"
              >
              </el-date-picker>
        </div>
        <div class="item">
          <span class="label">{{$t('bill.name')}}</span>
          <el-input class="inp" size="small" v-model="formData.valName" :placeholder="$t('bill.user_nickname')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{$t('device.name')}}</span>
          <el-input class="inp" size="small" v-model="formData.valDev" :placeholder="$t('device.name')"></el-input>
        </div>
        <!-- <div class="item" v-if="!isOperator()">
          <span class="label">{{$t('g.operator')}}</span>
          <el-select class="inp" size="small" v-model="formData.valCode" :placeholder="$t('g.choose')">
              <el-option
                v-for="item in render.operators"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </div> -->
        <div class="item" >
          <span class="label">{{ $t('g.operator') }}</span>
          <el-cascader  size="small"  style="width: 100%;flex: 1;" :clearable="true" v-model="formData.valCode" :props="formData.props" :options="render.cascade_options_direct" />
        </div>
        <div class="item">
          <span class="label">{{$t('device.no')}}</span>
          <el-input class="inp" size="small" v-model="formData.valDevNo" :placeholder="$t('device.no')"></el-input>
        </div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.search" class="search" type="primary" size="small">{{$t('g.search')}}</el-button>
      </div>
    </div>
    <div class="card-box data-table">
      <div class="header">
        <span class="title">{{$t("g.unit_num")}}:{{formData.total}}，{{$t("g.per_page")}}:{{formData.pageSize}}</span>
        <div class="header-icons">
          <el-icon @click="func.showAddDataDialog()" class="el-icon-plus header-icon"><Plus /></el-icon>
          
        </div>
      </div>
      <el-table
            :data="formData.tableData"
            :stripe="true"
            style="width: 100%">
            <!-- <el-table-column type="expand">
            @expand-change="func.expand"
              <template #default="scope">
                <div class="table-detail">
                  <div class="item">仓信息加载中...</div>
                </div>
              </template>
            </el-table-column> -->
            <el-table-column
              prop="Id"
              label="#"
              width="80">
            </el-table-column>
            <el-table-column
              prop="Nickname"
              :label="$t('bill.nickname')"
              width="140">
            </el-table-column>
            <el-table-column
              prop="AgentCode"
              v-if="!isOperator()"
              :label="$t('g.agent_code')"
              width="100">
            </el-table-column>
            <el-table-column
              prop="OperatorName"
              :label="$t('bill.operator')"
              width="200">
            </el-table-column>
            <el-table-column
              prop="Money"
              width="120"
              :label="$t('bill.opt_money')">
            </el-table-column>
            <el-table-column
              prop="DevName"
              width="180"
              :label="$t('device.name')">
            </el-table-column>
            <el-table-column
              prop="DevId"
              width="240"
              :label="$t('device.dev_id')">
            </el-table-column>
            <el-table-column
              prop="Remark"
              min-width="200"
              :label="$t('bill.remark')">
            </el-table-column>
            <el-table-column
              prop="CreateAtStr"
              min-width="180"
              :label="$t('g.create_at')">
            </el-table-column>

          </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="func.pageChange"
        :page-size="formData.pageSize"
        :current-page="formData.curPage"
        :total="formData.total">
      </el-pagination>
    </div>

    <el-dialog
      :title="$t('bill.record_add')"
      v-model="formData.dialogVisible"
      width="50%">
      <el-form  label-width="80px" :model="formData.curData">
        <div class="el-form-row">
          <el-form-item :label="$t('g.operator')">
            <el-select class="inp" size="small" v-model="formData.valAddOperator" :placeholder="$t('g.choose')">
                <el-option
                  v-for="item in render.operatorList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
          </el-form-item>
        </div>
        <div class="el-form-row">
          <el-form-item :label="$t('device.type')">
            <el-select class="inp" size="small" v-model="formData.valAddDevType" :placeholder="$t('g.choose')">
                <el-option
                  v-for="item in render.devTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
          </el-form-item>
        </div>
        <div class="el-form-row">
          <el-form-item :label="$t('device.dev_id')">
            <el-select filterable class="inp" size="small" v-model="formData.billData.devId" :placeholder="$t('g.choose')">
                <el-option
                  v-for="item in formData.devices"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
          </el-form-item>
        </div>
        <div class="el-form-row">
          <el-form-item :label="$t('device.total')">
            <el-input :placeholder="$t('device.ph.negative_as_cost')" size="small" type="number" v-model="formData.billData.money"></el-input>
          </el-form-item>
        </div>
        <div class="el-form-row">
          <el-form-item :label="$t('device.user_id')">
            <el-input :placeholder="$t('device.ph.user_id')" size="small" type="number" v-model="formData.billData.userId"></el-input>
          </el-form-item>
        </div>
        <div class="el-form-row">
          <el-form-item :label="$t('device.remark')">
            <el-input :placeholder="$t('device.ph.reason')"  type="textarea" v-model="formData.billData.remark"></el-input>
          </el-form-item>
        </div>

      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="func.submit()">{{$t('g.btn_confirm')}}</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import { reactive,onMounted,computed,watch } from 'vue'
import { shortcuts,operators,operatorList ,cascade_options_direct} from '@/data/default'
import { toDay } from '@/common/func'
import axios from "axios"
import { ElMessage,ElMessageBox,ElLoading } from 'element-plus'
import cfg from '@/common/conf'
import { user,isOperator } from '@/data/token'
import i18n from '@/data/i18n/index'

export default {
  name: 'BillBad',
  setup () {
    const t  = i18n.global.t
    const tableData = []
    const render = {
      devTypes:[
        {
          label: t("device.types.ebox"),
          value: 1
        },
        {
          label: t("device.types.cbox"),
          value: 2
        },
        {
          label: t("device.types.pile"),
          value: 3
        },
      ],
      operators,
      operatorList,
      shortcuts,
      cascade_options_direct
    }
    const formData = reactive({
      tableData,
      devices:[],
      valAddDevType:1,
      valAddOperator:(operatorList && operatorList.length)?operatorList[0].value:"",
      billData: {},
      valTime: '',
      valDevNo:"",
      valDev: "",
      curPage: 1,
      pageSize: 10,
      total: 0,
      valName:'',
      valCode:0,
      dialogVisible:false,
      props:{
          children:"Children",
          label:"Name",
          value:"CodePath",
          emitPath:false,
          checkStrictly:true
      },
    })
    watch(
      [
        ()=>formData.valAddDevType,
        ()=>formData.valAddOperator
      ],
      async ()=>{
        //获取设备列表
        const res = await axios.get(`/admin/device`,{
          params:{
            devType:formData.valAddDevType,
            agentCode:formData.valAddOperator,
          }
        })
        const devices = []
        for(let item of res){
          devices.push({
            label: item.Name,
            value: item.DevId
          })
        }
        formData.devices = devices
      },
      {immediate:true},
    )
    const func = {
      async submit(){
        const loading = ElLoading.service({
            lock: true,
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.7)',
          })
        await axios.put('/admin/bill',formData.billData)
        formData.billData = {}
        this.loadData()
        loading.close()
        formData.dialogVisible = false
        ElMessage({
          message:t("g.success"),
          type:'success'
        })
        console.log("billData",formData.billData)
      },
      showAddDataDialog(){
        formData.dialogVisible = true
      },
      async expand(row,state){
        if(state.length){
        }
      },
      search () {
        console.log('formData', formData)
        func.loadData()
      },
      pageChange (val) {
        console.log('page change', val)
        func.loadData(val)
      },
      async loadData(page=1){
        try{
          formData.tableData = []
          formData.curPage = page
          let params = {
              page,
          }
          if(formData.valDev){
            params.devName = formData.valDev
          }
          if(formData.valName){
            params.nickname = formData.valName
          }
          if(formData.valCode){
            params.agentCode = formData.valCode
          }
          if(formData.valDevNo){
            params.dev_id = formData.valDevNo
          }
          if(formData.valTime){
            params.startAt = Math.floor(formData.valTime[0].getTime()/1000)
            params.endAt = Math.floor(formData.valTime[1].getTime()/1000)
          }
          let res = await axios.get(`/admin/bill`,{
            params
          })
          formData.total = res.Total
          res.List.map((item)=>{
            item.Nickname = item.Nickname || "-"
            item.CreateAtStr = toDay(item.CreatedAt)
          })
          formData.tableData = res.List
          formData.pageSize = res.PageSize
        }catch(e){
          console.log("loadData",e)
        }
      }
    }
    onMounted(()=>{
      func.loadData()
    })
    return {
      func,
      render,
      formData,
      isOperator,
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;
  .table-detail{
    display: flex;
    flex-direction: column;
    margin-right: 9.375rem;
    padding-left: 4rem;
    padding-top: 1rem;
    .orders{
      .order{
        display:flex;
        margin-bottom: 0.5rem;
        align-items: center;
        >div{
          margin-right: 1rem;
        }
      }
    }
    >.item{
      margin-right: 0.625rem;
      display: flex;
      align-items: center;
      margin-bottom: 0.625rem;
      color: #333333;
      >img{
        margin-left: 0.625rem;
        width: 4rem;
        height: 3rem;
        object-fit: cover;
      }
    }
  }
  .boximg{
    width: 4rem;
    height: 3rem;
    object-fit: cover;
  }
  >.card-box{
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;
    .header{
      text-align:left;
      margin-bottom: 1.25rem;
      position: relative;
      >.title{
        font-size:1rem;
        font-weight: 500;
      }
      >.header-icons{
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;
        .header-icon{
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color:#969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }
    >.btn-ct{
      text-align: right;
      :deep(.el-button--small){
        padding: 0.625rem 2rem;
      }
    }
    .inp-list{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      >.item{
        width: 49%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;
        .label{
          margin-right: 0.625rem;
          width: 4.375rem;
        }
        :deep(.el-range-editor--small.el-input__inner){
          flex: 1;
        }
        .inp{
          flex: 1;
        }
      }
    }
  }
  >.el-alert-my{
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;
    .el-alert-my-txt{
      font-size: 0.875rem;
      >.tips{
        font-weight: bold;
      }
    }
    :deep(.el-alert__closebtn){
      top: 1.25rem;
      font-size: 1rem;
    }
  }
  >.data-table{
    :deep(.el-table){
      .cell{
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .el-table__row{
        cursor: pointer;
      }
    }
  }
  >.pagination{
    text-align: right;
    margin-top: 1.25rem;
  }
  .el-form-row{
    display:flex;
    justify-content: space-between;
    :deep(.el-form-item){
      width: 88%;
      margin: 0 auto;
    }
    &.line-3{
      :deep(.el-form-item){
        width: 33%;
      }
    }
    .inp{
      width: 100%;
    }

    .avatar-uploader{
      :deep(.el-upload){
        border: 1px dashed #d9d9d9;
        border-radius: 0.375rem;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        &:hover {
          border-color: #409eff;
        }
      }
    }
    .avatar-uploader-icon {
      font-size: 1.75rem;
      color: #8c939d;
      width: 11rem;
      height: 9rem;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .avatar {
      width: 11rem;
      height: 9rem;
      display: block;
      object-fit: cover;
    }
  }
  .el-select-my{
    text-align: left;
    display: block;
  }
}
</style>
