<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">{{ $t("g.condition") }}</span>
      </div>
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t("g.create_at") }}</span>
          <el-date-picker class="inp" v-model="formData.valTime" value-format="YYYY-MM-DD" size="small" type="daterange"
            unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
            :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
          </el-date-picker>
        </div>
        <div class="item">
          <span class="label">{{ $t("g.update_at") }}</span>
          <el-date-picker v-model="formData.valUpdateTime" :placeholder="$t('device.deadline') + $t('g.update_at')"
            type="datetime">
          </el-date-picker>
        </div>
        <div class="item">
          <span class="label">{{ $t("g.name") }}</span>
          <el-input class="inp" size="small" v-model="formData.valName" :placeholder="$t('device.holder')"></el-input>
        </div>
        <!-- <div class="item">
          <span class="label">{{ $t("g.operator") }}</span>
          <el-select class="inp" size="small" v-model="formData.valCode" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.operators" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div> -->
        <div class="item" >
          <span class="label">代理商</span>
          <el-cascader size="small"  style="width: 100%;flex: 1;" :clearable="true" v-model="formData.AgentCode" :props="formData.props2" :options="render.cascade_options_direct" />
        </div>
        <div class="item" >
          <span class="label">{{ $t('g.operator') }}</span>
          <el-cascader size="small"  style="width: 100%;flex: 1;" :clearable="true" v-model="formData.CodePath" :props="formData.props" :options="render.cascade_options_direct" />
        </div>
        <div class="item">
          <span class="label">{{ $t("device.charge_status") }}</span>
          <el-select class="inp" size="small" v-model="formData.status" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.batteryStatuses" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t("device.use_status.name") }}</span>
          <el-select class="inp" size="small" v-model="formData.useStatus" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.useStatuses2" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t("device.net_status.name") }}</span>
          <el-select class="inp" size="small" v-model="formData.onlineStatus" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.onlineStatuses" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t("device.device_id") }}</span>
          <el-input class="inp" size="small" v-model="formData.valDevName"
            :placeholder="$t('device.device_id')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t("member.mobile") }}</span>
          <el-input class="inp" size="small" v-model="formData.valPhone" :placeholder="$t('member.mobile')"></el-input>
        </div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.clear" class="search" type="info" size="small">{{ $t("g.clear") }}</el-button>
        <el-button @click="func.search" class="search" type="primary" size="small">{{ $t("g.search") }}</el-button>
      </div>
    </div>
    <div class="card-box data-table">
      <div class="header">
        <span class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{
          $t("g.per_page")
        }}:{{ formData.pageSize }}</span>
        <div class="header-icons">
          <!-- <i @click="func.showAddDataDialog()" class="el-icon-plus header-icon"></i> -->
        </div>
      </div>
      <el-table :data="formData.tableData" :stripe="true" style="width: 100%" @row-click="func.showDetails">
        <el-table-column type="expand">
          <template #default="scope">
            <div class="table-detail">
              <div class="item">
                {{ $t("device.power") }}: {{ scope.row.Energy }}
              </div>
              <div class="item">
                {{ $t("device.slot_id") }}: {{ scope.row.EboxSlotId }}
              </div>
              <div class="item">
                {{ $t("device.soh") }}: {{ scope.row.Soh }}
              </div>
              <div class="item">
                {{ $t("device.volt") }}: {{ scope.row.Volt }}
              </div>
              <div class="item">
                {{ $t("device.cell_count") }}: {{ scope.row.CellCount }}
              </div>
              <div class="item">
                {{ $t("device.protect") }}: {{ scope.row.Protect }}
              </div>
              <div class="item">
                {{ $t("device.charge_status") }}: {{ scope.row.ChargeStatus }}
              </div>
              <div class="item">
                {{ $t("device.input_current") }}: {{ scope.row.InputCurrent }}
              </div>
              <div class="item">
                {{ $t("device.input_volt") }}: {{ scope.row.InputVolt }}
              </div>
              <div class="item">
                {{ $t("device.inner_input_current") }}:
                {{ scope.row.InnerInputCurrent }}
              </div>
              <div class="item">
                {{ $t("device.env_temp") }}: {{ scope.row.EnvTemp }}
              </div>
              <div class="item">
                {{ $t("device.cell_temp") }}: {{ scope.row.CellTemp }}
              </div>
              <div class="item">
                {{ $t("device.board_temp") }}: {{ scope.row.BoardTemp }}
              </div>
              <div class="item">
                {{ $t("device.longitude") }}: {{ scope.row.Longitude }}
              </div>
              <div class="item">
                {{ $t("device.latitude") }}: {{ scope.row.Latitude }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="Id" label="#" width="100"> </el-table-column>
        <el-table-column prop="DevId" :label="$t('device.device_id')" width="240" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="EboxDevId" :label="$t('openSlotsLit.dev_id')" width="240" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="AgentCode" :label="$t('g.agent_code')" width="100">
        </el-table-column>
        <el-table-column prop="NamePath" min-width="180" label="运营商归属" :show-overflow-tooltip='true'></el-table-column>
        <el-table-column :label="$t('device.holder')" width="140">
          <template #default="scope">
            <div>
              {{
                scope.row.UserInfo?.Nickname || scope.row.BatteryUserName || "-"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="BatteryUserPhone" width="120" :label="$t('member.mobile')">
          <template #default="scope">
            <div>
              {{
                scope.row.UserInfo?.Mobile || "-" || scope.row.BatteryUserPhone
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="Soc" width="120" :label="$t('device.soc')">
        </el-table-column>
        <el-table-column prop="Error" width="120" :label="$t('device.error_no')">
        </el-table-column>
        <el-table-column prop="ChargeCount" width="120" :label="$t('device.charge_count')">
        </el-table-column>
        <el-table-column width="130" :label="$t('device.charge_status')">
          <template #default="scope">
            <el-tag :type="scope.row.Status == 1
                ? 'warning'
                : scope.row.Status == 2
                  ? 'success'
                  : 'info'
              " effect="dark">
              {{
                scope.row.Status == 1
                  ? $t("device.statuses.charging")
                  : scope.row.Status == 2
                    ? $t("device.statuses.full")
                    : $t("device.statuses.normal")
              }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="130" :label="$t('device.move_status.name')">
          <template #default="scope">
            <el-tag :type="scope.row.State == 3
                ? 'warning'
                : scope.row.State == 0
                  ? 'success'
                  : 'info'
              " effect="dark">
              {{ render.moveStatusese[scope.row.State] }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="130" :label="$t('device.control_status.name')">
          <template #default="scope">
            <el-tag :type="scope.row.ControlState == 0
                ? 'warning'
                : scope.row.ControlState == 1
                  ? 'success'
                  : 'info'
              " effect="dark">
              {{ render.controlStatusese[scope.row.ControlState] }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="130" :label="$t('device.use_status.name')">
          <template #default="scope">
            <el-tag :type="scope.row.UseStatus == 2
                ? 'warning'
                : scope.row.UseStatus == 1
                  ? 'success'
                  : 'info'
              " effect="dark">
              {{ render.useStatuses[scope.row.UseStatus + 1].label }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="130" :label="$t('device.net_status.name')">
          <template #default="scope">
            <el-tag :type="scope.row.NetStatus == 0
                ? 'warning'
                : scope.row.NetStatus == 1
                  ? 'success'
                  : 'info'
              " effect="dark">
              {{ render.onlineStatuses[scope.row.NetStatus + 1].label }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="Rate" width="170" :label="$t('device.rate')">
        </el-table-column>
        <el-table-column prop="Longitude" width="170" :label="$t('device.longitude')">
        </el-table-column>
        <el-table-column prop="Latitude" width="170" :label="$t('device.latitude')">
        </el-table-column>
        <el-table-column prop="CreateAtStr" min-width="200" :label="$t('g.create_at')">
        </el-table-column>
        <el-table-column prop="UpdateAtStr" min-width="200" :label="$t('g.update_at')">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="120">
          <template #default="scope">
            <el-button
              type="primary"
              size="small"
              @click="func.viewMap(scope.row)"
              >电池轨迹</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
        :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
      </el-pagination>
    </div>
    <el-dialog :title="$t('g.detail')" v-model="formData.dialogDisplayVisible" width="70%">
      <el-descriptions border>
        <el-descriptions-item :label="$t('device.device_id')">{{
          formData.curData.DevId
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('openSlotsLit.dev_id')">{{
          formData.curData.EboxDevId
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.agent_code')">{{
          formData.curData.AgentCode
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.holder')">{{
          formData.curData.UserInfo?.Nickname
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.mobile')">{{
          formData.curData.UserInfo?.Mobile
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.soc')">{{
          formData.curData.Soc
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.error_no')">{{
          formData.curData.Error
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.charge_count')">{{
          formData.curData.ChargeCount
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.charge_status')">
          <el-tag :type="formData.curData.Status == 1
              ? 'warning'
              : formData.curData.Status == 2
                ? 'success'
                : 'info'
            " effect="dark">
            {{
              formData.curData.Status == 1
                ? $t("device.statuses.charging")
                : formData.curData.Status == 2
                  ? $t("device.statuses.full")
                  : $t("device.statuses.normal")
            }}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('device.move_status.name')">
          <el-tag :type="formData.curData.State == 3
              ? 'warning'
              : formData.curData.State == 0
                ? 'success'
                : 'info'
            " effect="dark">
            {{ render.moveStatusese[formData.curData.State] }}
          </el-tag></el-descriptions-item>
        <el-descriptions-item :label="$t('device.control_status.name')">
          <el-tag :type="formData.curData.ControlState == 0
              ? 'warning'
              : formData.curData.ControlState == 1
                ? 'success'
                : 'info'
            " effect="dark">
            {{ render.controlStatusese[formData.curData.ControlState] }}
          </el-tag></el-descriptions-item>
        <el-descriptions-item :label="$t('device.use_status.name')">
          <el-tag :type="formData.curData.UseStatus == 2
              ? 'warning'
              : formData.curData.UseStatus == 1
                ? 'success'
                : 'info'
            " effect="dark">
            {{ render.useStatuses[formData.curData.UseStatus + 1].label }}
          </el-tag></el-descriptions-item>
        <el-descriptions-item :label="$t('device.net_status.name')">
          <el-tag :type="formData.curData.NetStatus == 0
              ? 'warning'
              : formData.curData.NetStatus == 1
                ? 'success'
                : 'info'
            " effect="dark">
            {{ render.onlineStatuses[formData.curData.NetStatus + 1].label }}
          </el-tag></el-descriptions-item>
        <el-descriptions-item :label="$t('device.rate')">{{
          formData.curData.Rate
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.longitude')">{{
          formData.curData.Longitude
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.latitude')">{{
          formData.curData.Latitude
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.address')">{{
          formData.curData.address
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.power')">{{
          formData.curData.Energy
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.slot_id')">{{
          formData.curData.EboxSlotId
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.soh')">{{
          formData.curData.Soh
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.volt')">{{ formData.curData.Volt / 10 }}V</el-descriptions-item>
        <el-descriptions-item :label="$t('device.cell_count')">{{
          formData.curData.CellCount
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.protect')">{{
          formData.curData.Protect
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.charge_status')">{{
          formData.curData.ChargeStatus
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.input_current')">{{ formData.curData.InputCurrent / 10
          }}A</el-descriptions-item>
        <el-descriptions-item :label="$t('device.input_volt')">{{ formData.curData.InputVolt / 10
          }}V</el-descriptions-item>
        <el-descriptions-item :label="$t('device.inner_input_current')">{{ formData.curData.InnerInputCurrent / 10
          }}A</el-descriptions-item>
        <el-descriptions-item :label="$t('device.env_temp')">{{ formData.curData.EnvTemp
          }}&#8451;</el-descriptions-item>
        <el-descriptions-item :label="$t('device.cell_temp')">{{ formData.curData.CellTemp
          }}&#8451;</el-descriptions-item>
        <el-descriptions-item :label="$t('device.board_temp')">{{ formData.curData.BoardTemp
          }}&#8451;</el-descriptions-item>
        <el-descriptions-item :label="$t('g.create_at')">{{
          formData.curData.CreateAtStr
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.update_at')">{{
          formData.curData.UpdateAtStr
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.battery_update_at')">{{
          formData.curData.BatUpdateAtStr
        }}</el-descriptions-item>
      </el-descriptions>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="default" @click="formData.dialogDisplayVisible = false">{{ $t("g.btn_cancel") }}</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="formData.trajectoryMap" title="电池地图轨迹" width="92%" top="3vh" class="mapDialog" @close="func.mapClose">
      <div id="map"></div>
      <div class="map_info">
        <div class="map_title">轨迹信息</div>
        <div class="info_top">轨迹最初时间：{{ formData.mapInfo.strTime }}</div>
        <div class="info_top">轨迹最初地点：{{ formData.mapInfo.strTitle }}</div>
        <div class="info_top">轨迹最终时间：{{ formData.mapInfo.endTime}}</div>
        <div class="info_top">轨迹最终地点：{{ formData.mapInfo.endTitle }}</div>
      </div>
    </el-dialog>
  </div>
</template>
<script >
import { reactive, onMounted, computed,nextTick} from "vue";
import { shortcuts, operators,getCoord,cascade_options_direct } from "@/data/default";
// import { user } from "@/data/token";
import { toDay } from "@/common/func";
import axios from "axios";
import i18n from "@/data/i18n/index";
import { ElMessage } from "element-plus";
export default {
  name: "BatteryList",
  components: {},
  setup() {
    const t = i18n.global.t;

    const AMap = window.AMap
    let map = null
    let marker,lineArr,polyline,passedPolyline;
    const tableData = [];
    const batteryStatuses = [
      {
        label: t("device.statuses.all"),
        value: 3,
      },
      {
        label: t("device.statuses.normal"),
        value: 0,
      },
      {
        label: t("device.statuses.charging"),
        value: 1,
      },
      {
        label: t("device.statuses.full"),
        value: 2,
      },
    ];
    const moveStatusese = [
      t("device.move_status.move"),
      t("device.move_status.still"),
      t("device.move_status.storage"),
      t("device.move_status.hibernate"),
    ];
    const controlStatusese = [
      t("device.control_status.discharge"),
      t("device.control_status.charge"),
      t("device.control_status.load"),
      t("device.control_status.charged"),
      t("device.control_status.empty"),
    ];
    const onlineStatuses = [
      {
        label: t("device.statuses.all"),
        value: -1,
      },
      {
        label: t("device.net_status.offline"),
        value: 0,
      },
      {
        label: t("device.net_status.online"),
        value: 1,
      },
    ];
    const useStatuses = [
      {
        label: t("device.statuses.all"),
        value: -1,
      },
      {
        label: t("device.use_status.idle"),
        value: 0,
      },
      {
        label: t("device.use_status.cabinet"),
        value: 1,
      },
      {
        label: t("device.use_status.rider"),
        value: 2,
      },
      {
        label: t("device.use_status.uncabinet"),
        value: 3,
      },
    ];
    const useStatuses2 = [
      {
        label: t("device.statuses.all"),
        value: -1,
      },
      {
        label: t("device.use_status.idle"),
        value: 0,
      },
      {
        label: t("device.use_status.cabinet"),
        value: 1,
      },
      {
        label: t("device.use_status.rider"),
        value: 2,
      },
    ];
    const render = {
      shortcuts,
      operators,
      batteryStatuses,
      onlineStatuses,
      useStatuses,
      useStatuses2,
      moveStatusese,
      controlStatusese,
      cascade_options_direct
    };
    const formData = reactive({
      tableData,
      curData: {},
      valTime: "",
      curPage: 1,
      pageSize: 10,
      status: 3,
      total: 0,
      valName: "",
      CodePath: 0,
      AgentCode:'',
      valDevName: "",
      dialogDisplayVisible: false,
      trajectoryMap:false,
      mapInfo:{
        strTime:'',
        strTitle:'',
        endTime:'',
        endTitle:''
      },
      props:{
          children:"Children",
          label:"Name",
          value:"CodePath",
          emitPath:false,
          checkStrictly:true
      },
      props2:{
          children:"Children",
          label:"Name",
          value:"Code",
          emitPath:false,
          checkStrictly:true
      },
    });
    const func = {
      geocoder(lon, lat) {
        let params = {
          key: "B2UBZ-JT7KN-BVRFJ-SJHR5-APMUJ-DEFGC",
          location: lat + "," + lon,
        };
        axios({
          url:
            `/tenxun/ws/geocoder/v1/?key=` +
            params.key +
            "&location=" +
            params.location,
          method: "get",
          headers: { "content-type": "application/json" },
        })
          .then(function (res) {
            console.log(res.result.address);
            formData.curData.address = res.result.address;
          })
          .catch(function (err) {
            // handle error
            console.log(err, 230);
          });
      },
      // 坐标转换
      async convertFrom(lnglat, type){
        return new Promise((resolve, reject) => {
          AMap.convertFrom(lnglat, type, function (status, result) {
            if (result.info === 'ok') {
              var resLnglat = result.locations[0];
              resolve(result.locations)
            }
          });
        })
      },
      async getAddressByCoordinates(arr) {  
          let geocoder = new AMap.Geocoder();
          // 返回一个 Promise 对象  
          return new Promise((resolve, reject) => {  
              // 调用 geocoder.getAddress 方法，并传入经纬度作为参数  
              geocoder.getAddress(arr, function(status, result) {  
                  // 检查状态是否为 'complete' 并且结果中是否包含 regeocode  
                  if (status === 'complete' && result.regeocode) {  
                      // 如果成功，则通过 resolve 方法返回地址信息  
                      resolve(result.regeocode.formattedAddress);  
                  } else {  
                      // 如果失败，则通过 reject 方法返回错误  
                      reject('根据经纬度查询地址失败');  
                  }  
              });  
          });  
      },
      async GetPathParam(list){
        let graspRoad = new AMap.GraspRoad()
        let arr = []
       for(let i=0;i<list.length;i++){
          let data = {
            x:list[i].Longitude,
            y:list[i].Latitude,
            sp:19,
            ag:0,
            tm:list[i].Created_at
          }
          if(i==0){
            data.tm = list[i].Created_at
          }else{
            data.tm = list[i].Created_at - list[0].Created_at
          }
          arr.push(data)
       }
      return new Promise((resolve, reject) => {
        graspRoad.driving(arr,function(error,result){
          console.log(result,error)
          if(!error){
            var path = [];
            var newPath = result.data.points;
            for(var i =0;i<newPath.length;i+=1){
              path.push([newPath[i].x,newPath[i].y])
            }
            resolve(path); 
          }
        })
      })
      },
      async viewMap(row){
        let params = {
          AgentCode:row.AgentCode,
          BatId:row.DevId
        }
        let res = await axios.get(`/admin/battery/battrack`, {
          params,
          AgentCode:''
        });
        console.log(res,'数据1')
        if(!res.List||res.List.length == 0){
          ElMessage({
            message: '暂无轨迹数据',
            type: 'warning',
          })
          return
        }
        let list = res.List
        function removeEveryOtherElement(arr) {  
          let newArray = [arr[0]];
          let lastdata = arr[arr.length - 1]
          let arr2 =  _.cloneDeep(arr)
          arr2.shift()
          arr2.pop();
          // 遍历除了第一个和最后一个数组之外的所有数组  
          const shrunkArray = arr2.filter((_, index) => index % 2 === 0);  
          newArray.push(...shrunkArray);  
          newArray.push(lastdata);  
          return newArray;
        }
        //if(list.length>=50){
         // list = removeEveryOtherElement(list)
        //}
        
        // let list2 = _.cloneDeep(list)
        // let path = await func.GetPathParam(list2)
        list = list.map(obj => {  
          let time = toDay(obj.Created_at);
          obj.Created_at = time;
          return Object.values(obj);  
        });
        //list =  await func.convertFrom(list,'gps') 
        formData.mapInfo.strTime = list[0][2]
        formData.mapInfo.endTime = list[list.length-1][2]
        formData.mapInfo.strTitle = await func.getAddressByCoordinates([list[0][0],list[0][1]]);
        formData.mapInfo.endTitle = await func.getAddressByCoordinates([list[list.length-1][0],list[list.length-1][1]]);
        
        formData.trajectoryMap = true
        await nextTick()
        initMap(list)
      },
      mapClose(){
        map.clearMap()
        polyline = null
        passedPolyline = null
        map.destroy()
        map = null
        console.log('关闭')
      },
      async getAddress(lon, lat) {
        let params = {
          lon,
          lat,
        };
        let res = await axios.get(`/admin/battery/address`, {
          params,
        });
        return res;
      },
      async showDetails(row, column) {
        if (column && column.label == "操作") {
          return;
        }
        formData.dialogDisplayVisible = true;
        formData.curData = row;
        if (row.Longitude && row.Latitude) {
          let address = await func.getAddress(row.Longitude, row.Latitude);
          formData.curData.address = address;
        }

        // console.log(JSON.stringify(row),23);
      },
      search() {
        console.log("formData", formData);
        func.loadData();
      },
      pageChange(val) {
        console.log("page change", val);
        func.loadData(val);
      },
      clear() {
        formData.valName = "";
        formData.CodePath = "";
        formData.AgentCode = "";
        formData.valPhone = "";
        formData.status = "";
        formData.useStatus = -1;
        formData.onlineStatus = -1;
        formData.valTime = "";
        formData.valUpdateTime = "";

        func.loadData();
      },
      async loadData(page = 1) {
        try {
          formData.tableData = [];
          formData.curPage = page;
          let params = {
            page,
          };
          if (formData.valName) {
            params.name = formData.valName;
          }
          if (formData.valUpdateTime) {
            params.UpdateEnd = Math.floor(
              Date.parse(formData.valUpdateTime) / 1000
            );
          }
          if (formData.CodePath) {
            params.CodePath = formData.CodePath;
          }
          if (formData.AgentCode) {
            params.AgentCode = formData.AgentCode;
          }
          if (formData.valPhone) {
            params.phoneNum = formData.valPhone;
          }
          params.status = formData.status;
          console.log(JSON.stringify(formData.useStatus));

          if (formData.useStatus >= 0) {
            params.UseState = formData.useStatus;
          }
          if (formData.onlineStatus >= 0) {
            params.NetState = formData.onlineStatus;
          }

          console.log(JSON.stringify(params));

          if (formData.valTime) {
            params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000);
            params.endAt = Math.floor(
              Date.parse(formData.valTime[1] + " 23:59:59") / 1000
            );
          }
          if (formData.valDevName) {
            params.devId = formData.valDevName;
          }
          let res = await axios.get(`/admin/battery`, {
            params,
          });
          console.log(res,'列表数据')
          formData.total = res.Total;
          res.List.map((item) => {
            item.CreateAtStr = toDay(item.CreateAt);
            item.UpdateAtStr = toDay(item.UpdateAt);
            item.BatUpdateAtStr =
              item.BatUpdateAt != 0 ? toDay(item.BatUpdateAt) : t("g.null");
            item.BatUpdateAtStr =
              item.BatUpdateAt != 0 ? toDay(item.BatUpdateAt) : t("g.null");
          });
          formData.tableData = res.List;
          formData.pageSize = res.PageSize;
        } catch (e) {
          console.log("loadData", e);
        }
      },
    };

    const initMap = async (list) => {
      const coord = await getCoord()
      lineArr = [[116.478935,39.997761],[116.478939,39.997825],[116.478912,39.998549],[116.478912,39.998549],[116.478998,39.998555],[116.478998,39.998555],[116.479282,39.99856],[116.479658,39.998528],[116.480151,39.998453],[116.480784,39.998302],[116.480784,39.998302],[116.481149,39.998184],[116.481573,39.997997],[116.481863,39.997846],[116.482072,39.997718],[116.482362,39.997718],[116.483633,39.998935],[116.48367,39.998968],[116.484648,39.999861]];
      const center = list[0]
      // 初始化地图
      map = new AMap.Map('map', {
        zoom: 18, // 设置地图缩放级别
        center, // 设置地图中心点坐标
        resizeEnable:true,
        mapStyle: 'amap://styles/light',
      })
      marker = new AMap.Marker({
          map: map,
          position: center,
          icon: require("../../assets/img/ddc.png"),
          offset: new AMap.Pixel(-18, -31),//-36, -50
          autoRotation: true,
          angle:-90,
      });
      
      // 绘制轨迹
      polyline = new AMap.Polyline({
          map: map,
          path: list,
          showDir:true,
          strokeColor: "#28F",  //线颜色
          // strokeOpacity: 1,     //线透明度
          strokeWeight: 2,//6      //线宽
          // strokeStyle: "solid"  //线样式
      });
      passedPolyline = new AMap.Polyline({
          map: map,
          strokeColor: "#AF5",  //线颜色
          strokeWeight: 2,//6      //线宽
      });
      marker.on('moving', function (e) {
        passedPolyline.setPath(e.passedPath);
      });
      map.setFitView();
      setTimeout(function(){
        marker.moveAlong(list, 500);
      },1000)
    }
    // async function setMap(){
    //   map.setCenter([114.064267,22.543626]);//设置地图中心坐标
    //   marker.setPosition([116.484648, 39.999861])
    //   polyline.setPath(lineArr)
    //   map.setFitView();
    //   marker.moveAlong(lineArr, 200);
    // }
    onMounted(() => {
      func.loadData();
    });
    return {
      func,
      render,
      formData,
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;

  .table-detail {
    display: flex;
    flex-direction: column;
    margin-right: 9.375rem;
    padding-left: 4rem;
    padding-top: 1rem;

    >.item {
      margin-right: 0.625rem;
      display: flex;
      align-items: center;
      margin-bottom: 0.625rem;
      color: #333333;

      >img {
        margin-left: 0.625rem;
        width: 4rem;
        height: 3rem;
        object-fit: cover;
      }
    }
  }

  .boximg {
    width: 4rem;
    height: 3rem;
    object-fit: cover;
  }

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;

      >.title {
        font-size: 1rem;
        font-weight: 500;
      }

      >.header-icons {
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;

        .header-icon {
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color: #969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }

    >.btn-ct {
      text-align: right;

      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }

    .inp-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: left;

      >.item {
        width: 33%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;

        .label {
          margin-right: 0.625rem;
          width: 4.375rem;
        }

        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }

        .inp {
          flex: 1;
        }
      }
    }
  }

  >.el-alert-my {
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;

    .el-alert-my-txt {
      font-size: 0.875rem;

      >.tips {
        font-weight: bold;
      }
    }

    :deep(.el-alert__closebtn) {
      top: 1.25rem;
      font-size: 1rem;
    }
  }

  >.data-table {
    :deep(.el-table) {
      .cell {
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .el-table__row {
        cursor: pointer;
      }
    }
  }

  >.pagination {
    text-align: right;
    margin-top: 1.25rem;
  }

  .el-form-row {
    display: flex;
    justify-content: space-between;

    :deep(.el-form-item) {
      width: 49%;
    }

    &.line-3 {
      :deep(.el-form-item) {
        width: 33%;
      }
    }

    .inp {
      width: 100%;
    }

    .avatar-uploader {
      :deep(.el-upload) {
        border: 1px dashed #d9d9d9;
        border-radius: 0.375rem;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        &:hover {
          border-color: #409eff;
        }
      }
    }

    .avatar-uploader-icon {
      font-size: 1.75rem;
      color: #8c939d;
      width: 11rem;
      height: 9rem;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .avatar {
      width: 11rem;
      height: 9rem;
      display: block;
      object-fit: cover;
    }
  }

  .el-select-my {
    text-align: left;
    display: block;
  }

  :deep(.m-dialog) {
    max-width: 80rem;
  }
}

.dialogSet {
  height: 720px;
  overflow-y: auto;
}

#map{
  width: 100%;
  height: 86vh;
  position: relative;
}

::v-deep .mapDialog .el-dialog__body{
  padding: 0 !important;
}
.map_info{
  width: 260px;
  background-color: #fff;
  position: absolute;
  left: 15px;
  top: 70px;
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  text-align: left;
  padding: 10px;
}
.info_top{
  margin-top: 5px;
}
.map_title{
  font-size: 18px;
  font-weight: bold;
  
  text-align: center;
}

</style>
